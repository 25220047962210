import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';

const InputWrapper = styled('div')`
    font-size: 0;
`;

const excludedProps = ['disabled'];

const TextInput = styled('input', { shouldForwardProp: prop => excludedProps.indexOf(prop) === -1 })`
    border: 1px solid ${theme.color.grey};
    width: 100%;
    font-size: 16px;
    line-height: 1;
    padding: 16px 20px;
    border-radius: 100px;

    color: ${theme.color.black};
    background-color: ${theme.color.white};

    transition: color 0.2s, border-color 0.2s, background-color 0.2s;

    &:placeholder-shown {
        background-color: ${theme.color.light_grey};
        border-color: ${theme.color.grey};

        &:hover {
            border-color: ${theme.color.dark_grey};
        }
    }

    &:focus, &:focus:hover {
        background-color: ${theme.color.white};
        border-color: ${theme.color.black};
    }

    &:disabled, &:disabled:hover {
        background-color: ${theme.color.light_grey};
        border-color: ${theme.color.grey};

    }

    .invalidinput & {
        border-color: ${theme.color.berries};
    }

    .darkinput & {
        color: ${theme.color.white};
        background-color: transparent;
        border-color: ${theme.color.white};

        &:placeholder-shown {
            &:hover {
                border-color: ${theme.color.white};
                background-color: rgba(255, 255, 255, 0.1);
            }
        }

        &:focus, &:focus:hover {
            color: ${theme.color.black};
            background-color: ${theme.color.white};
            border-color: ${theme.color.black};
        }

        // No space between classname and & here, since it's needed for sass to
        // create .invalidinput.darkinput (.darkinput is represented by "&" here)
        .invalidinput& {
            border-color: ${theme.color.berries};
            background-color: ${theme.color.white};
            color: ${theme.color.black};
        }

    }
`;

const InputLabel = styled('label')`
    display: inline-block;
    font-size: 12px;
    padding: 0 20px 8px;
    color: ${({ disabled }) => (disabled ? theme.color.dark_grey : theme.color.black)};
    transition: color 0.2s;

    .invalidinput & {
        color: ${theme.color.berries};

        .darkinput &{
            color: ${theme.color.white};
        }
    }

    .darkinput &{
        color: ${theme.color.white};

        // No space between classname and & here, since it's needed for sass to
        // create .invalidinput.darkinput (.darkinput is represented by "&" here)
        .invalidinput& {
            color: ${theme.color.white};
        }
    }
`;

const Input = ({disabled, invalid, label, name, dark, required, type, onChange, ...rest}) => {
    return (
        <InputWrapper className={`inputwrapper ${invalid ? 'invalidinput' : ''} ${dark ? 'darkinput' : ''}`}>
            {label &&
                <InputLabel className="label" disabled={disabled}>{label}{required && ' *'}</InputLabel>
            }
            <TextInput {...rest} disabled={disabled} name={name} type={type} placeholder=" " required={required} onChange={onChange} />
        </InputWrapper>
    );
};

Input.propTypes = {
    dark: PropTypes.bool,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    type: PropTypes.oneOf(['text', 'tel', 'email', 'password']),
};

Input.defaultProps = {
    dark: false,
    disabled: false,
    invalid: false,
    label: '',
    onChange: null,
    required: false,
    type: 'text'
};

export default Input;
