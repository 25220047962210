import React from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import Arrow from 'src/icons/Arrow';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Text from 'src/components/Text';
import theme from 'src/styles/theme';

const MobileArrow = styled(Arrow)`
    transition: transform 0.2s;

    ${above.tablet_s} {
        display: none;
    }
`;

const BaseLink = styled('span')`
    ${above.tablet_s} {
        margin-right: 20px;
        border-bottom: 1px solid transparent;
        cursor: pointer;

        &:hover {
            border-bottom: 1px solid ${theme.color.black};
        }

        ${below.desktop_s} {
            margin-right: 12px;
            width: 100%;

            &.hiddenOnHandheld {
                display: none;
            }
        }
    }

    ${below.tablet_s} {
        justify-content: space-between;
        width: 100%;
        display: inline-flex;
        align-items: center;

        &:hover {
            .mobileArrowSvg {
                transform: translate3d(10px, 0, 0)
            }
        }
    }
`;

const BaseLinkText = styled(Text)`
    color: ${theme.color.black};
    font-family: 'Tiempos';
    font-size: 24px;

    ${below.desktop_s} {
        &.hiddenOnHandheld {
            display: none;
        }
    }

    ${above.tablet_s} {
        font-family: 'SuisseIntL';
        font-size: 16px;
    }
`;

const HeaderLink = ({
    activeClassName,
    activeStyle,
    children,
    color,
    hideMobileLabel,
    onClick,
    partiallyActive,
    src,
    style,
    ...rest
}) => {
    return(
        <BaseLink
            activeClassName={activeClassName}
            activeStyle={activeStyle}
            partiallyActive={partiallyActive}
            style={{color, ...style}}
            src={src}
            onClick={onClick}
            {...rest}
        >
            <BaseLinkText as="span" className={`${hideMobileLabel ? 'hiddenOnHandheld' : ''}`}>{children}</BaseLinkText>
            <MobileArrow className="mobileArrowSvg" iconColor={color} />
        </BaseLink>
    );
};

HeaderLink.propTypes = {
    activeClassName: PropTypes.string, // a class name that will only be added to the Link when the current item is active
    activeStyle: PropTypes.object, // a style object that will only be applied when the current item is active
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    color: PropTypes.string,
    hideMobileLabel: PropTypes.bool,
    onClick: PropTypes.func,
    partiallyActive: PropTypes.bool, // https://www.gatsbyjs.com/docs/gatsby-link/#show-active-styles-for-partially-matched-and-parent-links
    src: PropTypes.string.isRequired,
    style: PropTypes.object,
};

HeaderLink.defaultProps = {
    activeClassName: '', // a class name that will only be added to the Link when the current item is active
    activeStyle: {}, // a style object that will only be applied when the current item is active
    color: theme.color.black,
    hideMobileLabel: false,
    onClick: null,
    partiallyActive: null, // https://www.gatsbyjs.com/docs/gatsby-link/#show-active-styles-for-partially-matched-and-parent-links
    style: {},
};

export default HeaderLink;