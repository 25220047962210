import React from 'react';
import SVG from './base/SVG';
import Path from './base/Path';
import PropTypes from 'prop-types';

const Spinner = ({ color, strokeWidth, ...props }) => (
    <SVG {...props}>
        <Path
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            fill="none"
            fillRule="evenodd"
            d="M8 1C4.1340068 1 1 4.1340068 1 8c0 3.86599325 3.1340068 7 7 7 3.86599325 0 7-3.13400675 7-7"
        ></Path>
    </SVG>
);

Spinner.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    strokeWidth: PropTypes.string,
    viewBox: PropTypes.string,
    width: PropTypes.string,
};

Spinner.defaultProps = {
    color: '#000',
    height: '100%',
    strokeWidth: '1',
    viewBox: '0 0 16 16',
    width: '100%',
};

export default Spinner;
