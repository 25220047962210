import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';
import { above } from 'src/components/global/mediaqueries';

const ButtonWrapper = styled('div')`
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    padding: 16px 32px;
    width: auto;
    user-select: none;

    &.small {
        font-size: 14px;
        padding: 10px 16px;
    }

    &.black {
        background-color: ${theme.color.black};
        color: ${theme.color.white};

        &:hover {
            background-color: ${theme.color.light_grey};
            color: ${theme.color.black};
        }
    }

    &.white {
        background-color: ${theme.color.white};
        color: ${theme.color.black};

        &:hover {
            background-color: ${theme.color.black};
            color: ${theme.color.white};
        }
    }

    &.outlined {
        background-color: transparent;
        box-shadow: 0 0 0 1px ${theme.color.black} inset;
        color: ${theme.color.black};

        ${above.desktop_s} {
            &:hover {
                background-color: ${theme.color.black};
                color: ${theme.color.white};
            }
        }

        &.active {
            background-color: ${theme.color.black};
            color: ${theme.color.white};
        }
    }

    &.rounded {
        border-radius: 90px;
    }

    &.disabled {
        background-color: ${theme.color.grey};
        color: ${theme.color.black};
        cursor: not-allowed;

        &.outlined, &.outlined:hover {
            background-color: transparent !important;
            box-shadow: 0 0 0 1px ${theme.color.dark_grey} inset !important;
            color: ${theme.color.dark_grey} !important;
        }
    }
`;

const Button = ({
    active,
    buttonColor,
    children,
    disabled,
    onClick,
    outlined,
    rounded,
    small,
    className,
    ...rest
}) => {
    return(
        <ButtonWrapper
            className={`${active && 'active'} ${disabled ? 'disabled' : buttonColor} ${outlined && 'outlined'} ${small && 'small'} ${rounded && 'rounded'} ${className}`}
            onClick={disabled ? null : onClick}
            {...rest}
        >
            {children}
        </ButtonWrapper>
    );
};

Button.propTypes = {
    active: PropTypes.bool,
    buttonColor: PropTypes.oneOf(['black', 'white']),
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    outlined: PropTypes.bool,
    rounded: PropTypes.bool,
    small: PropTypes.bool,
};

Button.defaultProps = {
    active: false,
    buttonColor: 'black',
    className: '',
    disabled: false,
    onClick: null,
    outlined: false,
    rounded: false,
    small: false,
};

export default Button;
