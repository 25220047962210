import React from 'react';
import styled from '@emotion/styled';

const TagWrapper = styled('div')`
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.08em;
`;

const Tag = ({ children, ...props }) => (
    <TagWrapper {...props}>
        {children}
    </TagWrapper>
);

export default Tag;
