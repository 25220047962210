import React from 'react';
import Path from 'src/icons/base/Path';
import SVG from 'src/icons/base/SVG';
import theme from 'src/styles/theme';

const Arrow = ({ iconColor, ...rest }) => (
    <SVG fill="none" {...rest}>
        <Path
            d="M0.829546 3L0.22509 4.49814L14.206 9.69627H0V11.3029H14.206L0.22509 16.5019L0.829546 18L21 10.4996L0.829546 3Z"
            fill={iconColor}
        />
    </SVG>
);

Arrow.defaultProps = {
    height: '21px',
    iconColor: theme.color.black,
    viewBox: '0 0 21 21',
    width: '21px',
};

export default Arrow;
