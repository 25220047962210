import React from 'react';
import Path from 'src/icons/base/Path';
import PropTypes from 'prop-types';
import SVG from 'src/icons/base/SVG';
import theme from 'src/styles/theme';

const MyPageIcon = ({filled, iconColor, ...rest }) => {
    const fillIcon = filled ? iconColor : null;
    return (
        <SVG width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <Path
                d="M0.683625 15.35C1.00856 12.2273 3.64855 9.79282 6.85714 9.79282H10.1429C13.3515 9.79282 15.9914 12.2273 16.3164 15.35H0.683625Z"
                fill={fillIcon}
                stroke={iconColor}
                stroke-width="1.3"
            />
            <Path
                d="M12.9492 5.14285C12.9492 7.62933 10.9517 9.63571 8.49917 9.63571C6.04662 9.63571 4.04917 7.62933 4.04917 5.14285C4.04917 2.65638 6.04662 0.65 8.49917 0.65C10.9517 0.65 12.9492 2.65638 12.9492 5.14285Z"
                fill={fillIcon}
                stroke={iconColor}
                stroke-width="1.3"
            />
        </SVG>
    );
};

MyPageIcon.propTypes = {
    filled: PropTypes.bool,
    iconColor: PropTypes.string,
};

MyPageIcon.defaultProps = {
    filled: false,
    iconColor: theme.color.black,
};

export default MyPageIcon;
