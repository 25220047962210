import ModuleNotFound from './modules/NotFound';
import loadable from '@loadable/component';
import inBrowser from 'src/utils/inBrowser';

const ModuleList = {
    // Templates
    analyst: './templates/analyst',
    category: './templates/category',
    checkout: './templates/checkout',
    index: './templates/index',
    page: './templates/page',
    post: './templates/post',
    posts: './templates/posts',
    product: './templates/product',
    ThankYou: './templates/ThankYou',
    '404': './components/Templates/404',
    account: './components/pages/account',
    'forgot-password': './components/pages/forgot-password',
    login: './components/pages/login',
    register: './components/pages/register',
    'reset-password': './components/pages/reset-password',
    'SearchResultsPage': './components/pages/SearchResultsPage',

    //Page Modules
    'analysts_module': './modules/Analysts',
    'content_module': './modules/Content',
    'faq_module': './modules/Faq',
    'hero_about_us': './components/Hero/HeroModuleAbout',
    'hero_image': './components/Hero/HeroModuleImage.js',
    'hero_product': './components/Hero/HeroModuleProduct',
    'hero_text': './components/Hero/HeroModuleText',
    'link_module': './modules/Link',
    'posts_module': './modules/Posts',
    'products_module': './modules/Products',
    'quote_module': './modules/Quote',
    'references_module': './modules/References',
    'single_post_module': './modules/SinglePost',
    'single_product_module': './modules/SingleProduct',
    'text_module': './modules/Text',
    'news_listing_module': './modules/NewsListing',
    'text_image_module': './modules/TextImage',

    //Post Modules
    'text_section': './modules/PostModules/TextSection',
    'image_section': './modules/PostModules/ImageSection',

    // Header Modules
    'links_report': './modules/Header/LinksReport',
    article: './modules/Header/Article',
};

const LoadModule = type => {

    const activeModule = ModuleList[type];
    const ModuleComponent = loadable(() => import(`${activeModule}`));

    if (!inBrowser) {
        // The server should always render the module so we get the static HTML.
        if (typeof activeModule === 'undefined') {
            return ModuleNotFound;
        }
        return ModuleComponent;
    }

    if (typeof activeModule === 'undefined') {
        return ModuleNotFound;
    }

    return ModuleComponent;
};

export default LoadModule;
