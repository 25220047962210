import { Link } from 'gatsby';
import { below } from 'src/components/global/mediaqueries';
import Heading from 'src/components/Heading';
import PlusMinusButton from 'src/components/buttons/PlusMinusButton';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';

const FooterSectionWrapper = styled('div')``;

const SectionHeadingWrapper = styled('div')`
    ${theme.spacing.mobile._20('padding')};
    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.mobile._12('padding-right')};

    ${theme.spacing.tablet._20('padding')};
    ${theme.spacing.tablet._24('padding-left')};
    ${theme.spacing.tablet._24('padding-right')};

    ${theme.spacing.desktop._20('padding')};
    ${theme.spacing.desktop._24('padding-left')};
    ${theme.spacing.desktop._24('padding-right')};

    .firstcolumn & {
        ${theme.spacing.tablet._32('padding-left')};
        ${theme.spacing.tablet._32('padding-right')};

        ${theme.spacing.desktop._32('padding-left')};
        ${theme.spacing.desktop._32('padding-right')};
    }

    border-bottom: 1px solid ${theme.color.black};
    position: relative;

    .toggleplus {
        display: none;
    }

    ${below.tablet_s} {
        .toggleplus {
            height: 30px;
            width: 30px;
            display: block;
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
`;

const SectionHeading = styled(Heading)`
    ${theme.fontSize.serif.mobile._24};
    ${theme.fontSize.serif.tablet._24};
    ${theme.fontSize.serif.desktop._24};

    margin: 0;
    padding: 0;
    line-height: 1;
    line-height: 1;
    font-family: 'Tiempos';
    font-weight: 300;
`;

const SectionContent = styled('div')`

    ${below.tablet_s} {
        max-height: 0;
        transition: max-height 0.2s, opacity 0.2s;
        overflow: hidden;
        opacity: 0;

        .active & {
            opacity: 1;
            max-height: 200px;
        }
    }
`;

const MenuWrapper = styled('div')`
    ${theme.spacing.mobile._20('padding-top')};
    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.mobile._12('padding-right')};
    ${theme.spacing.mobile._12('padding-bottom')};

    ${theme.spacing.tablet._20('padding')};
    ${theme.spacing.tablet._24('padding-left')};
    ${theme.spacing.tablet._24('padding-right')};
    ${theme.spacing.tablet._12('padding-bottom')};

    ${theme.spacing.desktop._20('padding')};
    ${theme.spacing.desktop._24('padding-left')};
    ${theme.spacing.desktop._24('padding-right')};
    ${theme.spacing.desktop._12('padding-bottom')};

    .firstcolumn & {
        ${theme.spacing.tablet._32('padding-left')};
        ${theme.spacing.tablet._32('padding-right')};

        ${theme.spacing.desktop._32('padding-left')};
        ${theme.spacing.desktop._32('padding-right')};
    }

    ${below.tablet_s} {
        border-bottom: 1px solid ${theme.color.black};
    }
`;

const FooterLink = styled(Link)`
    ${theme.spacing.mobile._10('margin-bottom')};
    ${theme.spacing.tablet._10('margin-bottom')};
    ${theme.spacing.desktop._10('margin-bottom')};

    display: inline-block;
    line-height: 1.35em;
    font-size: 16px;

    color: ${theme.color.black};
`;

const FooterSection = ({menuData, column}) => {
    const sectionHeader = useRef(null);
    const [showSection, setShowSection] = useState(false);

    const {
        heading,
        links,
    } = menuData;

    const toggleSection = (section) => {
        setShowSection(!showSection);
        section.current.classList.toggle('active');
    };

    return (
        <FooterSectionWrapper ref={sectionHeader} className={`${column === 0 ? 'firstcolumn' : ''} footercolumn`}>
            <SectionHeadingWrapper onClick={() => toggleSection(sectionHeader)}>
                <SectionHeading as="h3">{heading}</SectionHeading>
                <div className="toggleplus"><PlusMinusButton active={showSection} /></div>
            </SectionHeadingWrapper>
            <SectionContent>
                <MenuWrapper>
                    { links.map((menuItem, index) => {
                        return (
                            <div key={index}>
                                <FooterLink to={menuItem.link.url}>{menuItem.link.text}</FooterLink>
                            </div>
                        );
                    })}
                </MenuWrapper>
            </SectionContent>
        </FooterSectionWrapper>
    );
};

FooterSection.propTypes = {
    column: PropTypes.number,
    menuData: PropTypes.shape({
        heading: PropTypes.string,
        links: PropTypes.arrayOf(
            PropTypes.exact({
                link: PropTypes.shape({
                    text: PropTypes.string,
                    url: PropTypes.string,
                })
            })
        ),
    }),
};

FooterSection.defaultProps = {
    column: 0,
    menuData: {
        heading: '',
        links: [],
    },
};

export default FooterSection;
