import React from 'react';
import PropTypes from 'prop-types';

const Italic = ({ children }) => <em>{children}</em>;

Italic.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Italic;
