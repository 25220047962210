import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';
import { below } from 'src/components/global/mediaqueries';

const List = styled('ol')`
    padding: 0;
    margin: 0;
    list-style: none;
    counter-reset: custom-counter;

    ${below.tablet_s} {
        padding-left: 30px;
    }

    li {
        counter-increment: custom-counter;
        position: relative;

        &::before {
            content: counter(custom-counter) ".";
            display: inline-block;
            position: absolute;
            transform: translateX(-30px);
        }

        & + li {
            ${theme.spacing.mobile._16('margin-top')};
            ${theme.spacing.tablet._16('margin-top')};
            ${theme.spacing.desktop._16('margin-top')};
        }
    }
`;

const OrderedList = ({ children }) => {
    return (
        <List>
            {Array.isArray(children) ? children.map((item, index) => {
                return <li key={index}>{item.props.children}</li>;
            }) : <li>{children.props.children}</li> }
        </List>
    );
};

OrderedList.propTypes = {
    children: PropTypes.node.isRequired,
};

export default OrderedList;
