import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';

const Heading = styled('h4')`
    margin: 0;
    padding: 0;
    margin-top: 40px;
    font-weight: 300;
    line-height: 1.41em;

    ${theme.fontSize.sans.mobile._20};
    ${theme.fontSize.sans.tablet._20};
    ${theme.fontSize.sans.desktop._20};
`;

const HeadingLevelFour = ({ children }) => <Heading>{children}</Heading>;

HeadingLevelFour.propTypes = {
    children: PropTypes.node.isRequired,
};

export default HeadingLevelFour;