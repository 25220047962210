import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';

const isExternalSrc = (src) => !/^\/(?!\/)/.test(src);

const LinkComponent = ({
    activeClassName,
    activeStyle,
    children,
    onClick,
    partiallyActive,
    replace,
    src,
    style,
    state,
    ...rest
}) => {

    return !isExternalSrc(src) && !!src ?
        <Link
            activeClassName={activeClassName}
            activeStyle={activeStyle}
            partiallyActive={partiallyActive}
            replace={replace}
            style={style}
            to={src}
            state={state}
            onClick={onClick}
            {...rest}
        >
            {children}
        </Link>
    : src ?
        <a style={style} href={src} onClick={onClick} {...rest}>
            {children}
        </a>
    :
        <span style={style} onClick={onClick} {...rest}>
            {children}
        </span>;
};

LinkComponent.propTypes = {
    activeClassName: PropTypes.string, // a class name that will only be added to the Link when the current item is active
    activeStyle: PropTypes.object, // a style object that will only be applied when the current item is active
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    onClick: PropTypes.func,
    partiallyActive: PropTypes.bool, // https://www.gatsbyjs.com/docs/gatsby-link/#show-active-styles-for-partially-matched-and-parent-links
    replace: PropTypes.bool, // https://www.gatsbyjs.com/docs/gatsby-link/#replace-history-to-change-back-button-behavior
    src: PropTypes.string,
    state: PropTypes.object,
    style: PropTypes.object,
};

LinkComponent.defaultProps = {
    activeClassName: '', // a class name that will only be added to the Link when the current item is active
    activeStyle: {}, // a style object that will only be applied when the current item is active
    onClick: null,
    partiallyActive: null, // https://www.gatsbyjs.com/docs/gatsby-link/#show-active-styles-for-partially-matched-and-parent-links
    replace: false, // https://www.gatsbyjs.com/docs/gatsby-link/#replace-history-to-change-back-button-behavior
    src: null,
    state: null,
    style: {},
};

export default LinkComponent;
