import styled from '@emotion/styled';
import PropTypes from 'prop-types';

/**
 * Text takes all props a normal p or span can use.
 * Check out libs/styled to see how props can be passed into it.
 */

const Text = styled('p')`
	font-family: 'SuisseIntL';
	font-size: 16px;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 1;
`;

Text.propTypes = {
    as: PropTypes.oneOf(['p', 'span']),
    children: PropTypes.node.isRequired,
};

Text.defaultProps = {
    as: 'p',
};

export default Text;
