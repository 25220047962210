import Path from 'src/icons/base/Path';
import SVG from 'src/icons/base/SVG';
import React from 'react';
import theme from 'src/styles/theme';

const BergStar = ({ iconColor, ...rest }) => (
    <SVG fill="none" {...rest}>
        <Path
            d="m5.98267 96.8215 1.84892 4.1845 37.49941-16.5713-27.471 27.4713 3.2345 3.232 27.4709-27.4689-16.5711 37.4999 4.1843 1.848 23.9069-54.1023z"
            fill={iconColor}
        />
        <Path
            d="m127.018 36.1786-1.849-4.1843-37.497 16.5688 27.469-27.4686-3.235-3.2345-27.4708 27.471 16.5708-37.49941-4.184-1.84892-23.9045 54.10043z"
            fill={iconColor}
        />
        <Path
            d="m2.2685 45.1488-1.652962 4.265 38.232462 14.7982h-38.848v4.5739h38.848l-38.232462 14.8006 1.652962 4.265 55.1587-21.3525z"
            fill={iconColor}
        />
        <Path
            d="m132.384 49.4138-1.653-4.265-55.1582 21.3502 55.1582 21.3525 1.653-4.265-38.2321-14.8006h38.8481v-4.5739h-38.8481z"
            fill={iconColor}
        />
        <Path
            d="m36.1786 5.98267-4.1843 1.84892 16.5711 37.49941-27.4709-27.471-3.2345 3.2345 27.471 27.4686-37.49941-16.5688-1.84892 4.1843 54.10283 23.9045z"
            fill={iconColor}
        />
        <Path
            d="m96.822 127.017 4.184-1.848-16.5708-37.4999 27.4708 27.4689 3.235-3.232-27.4714-27.4713 37.4994 16.5713 1.849-4.1845-54.1005-23.9068z"
            fill={iconColor}
        />
        <Path
            d="m87.8516 2.26619-4.265-1.650652-14.7982 38.232462v-38.848h-4.5739v38.848l-14.8006-38.232462-4.265 1.650652 21.3502 55.16101z"
            fill={iconColor}
        />
        <Path
            d="m45.1489 130.732 4.265 1.652 14.8006-38.232v38.848h4.5739v-38.848l14.7982 38.232 4.265-1.652-21.3525-55.1591z"
            fill={iconColor}
        />
    </SVG>
);

BergStar.defaultProps = {
    height: '133px',
    iconColor: theme.color.black,
    viewBox: '0 0 133 133',
    width: '133px',
};

export default BergStar;
