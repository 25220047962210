import React, { useContext } from 'react';
import CartContext from 'src/libs/BergCommerceAPI/Basket/Context/Cart';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Text from 'src/components/Text';

const Cart = styled('div')`
    display: flex;
    height: 20px;
`;

const CartText = styled('div')`
    margin: auto 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1em;
`;

const QuantityText = styled(Text)`
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 1em;
    margin-left: 3px;
`;

const HeaderCart = ({basket}) => {
    const {cart} = useContext(CartContext);

    return (
        <Cart>
            <CartText> {basket.label} </CartText>
            <QuantityText> {cart.count} </QuantityText>
        </Cart>
    );
};

HeaderCart.propTypes = {
    basket: PropTypes.shape({
        'empty_label': PropTypes.string,
        label: PropTypes.string,
    })
};

HeaderCart.defaultProps = {
    basket: {
        'empty_label': 'Basket',
        label: 'Basket',
    }
};

export default HeaderCart;