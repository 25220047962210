import React, { useState, useEffect, Fragment } from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import Button from 'src/components/Button';
import Cookies from 'js-cookie';
import CrossButton from 'src/components/buttons/CrossButton';
import Heading from 'src/components/Heading';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Text from 'src/components/Text';
import theme from 'src/styles/theme';
import WysiwygWrapper from 'src/components/WysiwygWrapper';

const CookiePopupWrapper = styled('div')`
    position: fixed;
    z-index: 20000;
    left: 0;
    right: 0;
    bottom: 0;
    width: 600px;
    max-width: 100%;

    ${theme.spacing.mobile._24('padding')};
    ${theme.spacing.mobile._16('padding-left')};
    ${theme.spacing.mobile._16('padding-right')};

    ${theme.spacing.tablet._24('padding')};
    ${theme.spacing.desktop._24('padding')};
    ${theme.spacing.desktop._40('padding-bottom')};

    ${theme.spacing.mobile._12('padding')};
    ${above.tablet_s} {
        padding: 0;
    }

    ${theme.spacing.mobile._0('left')};
    ${theme.spacing.mobile._0('bottom')};
    ${theme.spacing.tablet._64('left')};
    ${theme.spacing.tablet._64('bottom')};
    ${theme.spacing.desktop._64('left')};
    ${theme.spacing.desktop._64('bottom')};
`;

const CookiePopup = styled('div')`
    background: ${theme.color.productcard};
    color: ${theme.color.black};

    ${theme.spacing.mobile._24('padding')};
    ${theme.spacing.mobile._16('padding-left')};
    ${theme.spacing.mobile._16('padding-right')};

    ${theme.spacing.tablet._24('padding')};
    ${theme.spacing.desktop._24('padding')};
    ${theme.spacing.desktop._40('padding-bottom')};
`;

const CookieHeading = styled(Heading)`
    ${theme.fontSize.sans.mobile._24};
    ${theme.fontSize.sans.tablet._24};
    ${theme.fontSize.sans.desktop._24};

    ${theme.spacing.mobile._16('margin-bottom')};
    ${theme.spacing.tablet._16('margin-bottom')};
    ${theme.spacing.desktop._16('margin-bottom')};

    line-height: 1;
    font-weight: 400;
`;

const CookieTextWrapper = styled('div')``;

const CookieText = styled(Text)`
    font-size: 16px;
    line-height: 1.6em;
`;

const CloseButton = styled('div')`
    position: absolute;
    cursor: pointer;

    ${theme.spacing.tablet._16('top')};
    ${theme.spacing.tablet._24('right')};
    ${theme.spacing.desktop._20('top')};
    ${theme.spacing.desktop._24('right')};

    > div {
        transform: rotate(45deg);
    }

    ${below.tablet_s} {
        display: none;
    }
`;

const ButtonWrapper = styled('div')`
    ${theme.spacing.mobile._24('margin-top')};

    ${above.tablet_s} {
        display: none;
    }
`;

const CookieConsent = ({data}) => {
    const [displayCookiePopup, setDisplayCookiePopup] = useState(false);

    const {
        heading,
        lifespan,
        text
    } = data;

    useEffect(() => {
        const cookie = Cookies.get('berg-accept-cookies');
        if (!cookie) {
            setDisplayCookiePopup(true);
        }
    }, [displayCookiePopup]);

    const setCookie = () => {
        Cookies.set('berg-accept-cookies', '1', { expires: parseInt(lifespan, 10) });
        setDisplayCookiePopup(false);
    };

    return (
        <Fragment>
            {displayCookiePopup &&
                <CookiePopupWrapper>
                    <CookiePopup>
                        <CookieHeading as="h4">{heading}</CookieHeading>
                        <CookieTextWrapper>
                            <WysiwygWrapper data={text} textComponent={CookieText} />
                        </CookieTextWrapper>
                        <ButtonWrapper>
                            <Button style={{width: '100%', textAlign: 'center'}} onClick={() => setCookie()}>OK</Button>
                        </ButtonWrapper>
                        <CloseButton onClick={() => setCookie()}>
                            <CrossButton />
                        </CloseButton>
                    </CookiePopup>
                </CookiePopupWrapper>
            }
        </Fragment>
    );
};

CookieConsent.propTypes = {
    data: PropTypes.shape({
        heading: PropTypes.string,
        lifespan: PropTypes.string,
        text: PropTypes.array,
    })
};

CookieConsent.defaultProps = {
    data: {
        heading: '',
        lifespan: '30',
        text: [],
    }
};

export default CookieConsent;