// Suisse Int'l regular
import SuisseIntLEot from './suisse-int-l/SuisseIntl-Regular-WebS.eot';
import SuisseIntLTtf from './suisse-int-l/SuisseIntl-Regular-WebS.ttf';
import SuisseIntLWoff from './suisse-int-l/SuisseIntl-Regular-WebS.woff';
import SuisseIntLWoff2 from './suisse-int-l/SuisseIntl-Regular-WebS.woff2';

// Suisse Int'l Medium
import SuisseIntLMediumEot from './suisse-int-l/SuisseIntl-Medium-WebS.eot';
import SuisseIntLMediumTtf from './suisse-int-l/SuisseIntl-Medium-WebS.ttf';
import SuisseIntLMediumWoff from './suisse-int-l/SuisseIntl-Medium-WebS.woff';
import SuisseIntLMediumWoff2 from './suisse-int-l/SuisseIntl-Medium-WebS.woff2';

import TiemposTextEot from './tiempos-text/tiempos-text-web-regular.eot';
//missing ttf
import TiemposTextWoff from './tiempos-text/tiempos-text-web-regular.woff';
import TiemposTextWoff2 from './tiempos-text/tiempos-text-web-regular.woff2';

const fonts = () => {
    return `
        @font-face {
          font-family: "SuisseIntL";
          font-weight: 400;
          font-style: normal;
          font-display: block;
          src: url(${SuisseIntLWoff2}) format("woff2"),
            url(${SuisseIntLWoff}) format("woff"),
            url(${SuisseIntLTtf}) format("ttf"),
            url(${SuisseIntLEot}) format("eot");
        }

        @font-face {
          font-family: "SuisseIntL";
          font-weight: 500;
          font-style: normal;
          font-display: block;
          src: url(${SuisseIntLMediumWoff2}) format("woff2"),
            url(${SuisseIntLMediumWoff}) format("woff"),
            url(${SuisseIntLMediumTtf}) format("ttf"),
            url(${SuisseIntLMediumEot}) format("eot");
        }

        @font-face {
          font-family: "Tiempos";
          font-weight: 400;
          font-style: normal;
          font-display: block;
          src: url(${TiemposTextWoff2}) format("woff2"),
            url(${TiemposTextWoff}) format("woff"),
            url(${TiemposTextEot}) format("eot");
        }
    `;
};

export default fonts;
