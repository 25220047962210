const indices = [
    {
        name: `${process.env.ALGOLIA_PREFIX}Posts`,
        title: `${process.env.ALGOLIA_PREFIX}Posts`,
    },
    {
        name: `${process.env.ALGOLIA_PREFIX}Products`,
        title: `${process.env.ALGOLIA_PREFIX}Products`,
    },
    {
        name: `${process.env.ALGOLIA_PREFIX}Posts_query_suggestions`,
        title: `${process.env.ALGOLIA_PREFIX}Posts_query_suggestions`,
    },
];

export default indices;