import React from 'react';
import PropTypes from 'prop-types';

const Bold = ({ children }) => <strong>{children}</strong>;

Bold.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Bold;
