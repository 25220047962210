import Arrow from 'src/icons/Arrow';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';

const ListItem = styled('li')`
    position: relative;

    & + & {
        ${theme.spacing.mobile._16('margin-top')};
        ${theme.spacing.tablet._16('margin-top')};
        ${theme.spacing.desktop._16('margin-top')};
    }

    > svg {
        position: absolute;
        top: 4px;
        left: -30px;
    }
`;

const UnorderedListItem = ({ children }) => <ListItem><Arrow width="16" height="16"/>{children}</ListItem>;

UnorderedListItem.propTypes = {
    children: PropTypes.node.isRequired,
};

export default UnorderedListItem;
