import React from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Tag from 'src/components/typography/Tag';
import Text from 'src/components/Text';
import theme from 'src/styles/theme';

const InfoTextWrapper = styled('div')`
    color: ${theme.color.black};
    width: 100%;

    ${theme.spacing.mobile._32('padding-top')};
    ${theme.spacing.tablet._0('padding-top')};
    ${theme.spacing.desktop._0('padding-top')};

    ${below.tablet_s} {
        min-height: 185px;
        margin-top: 50px;
        ${theme.spacing.mobile._40('padding-left')};
        ${theme.spacing.mobile._40('padding-right')};
    }

    ${above.tablet_s} {
        margin: 0 auto;
        width: 50%;
        height: 168px
    }

    ${above.desktop_s} {
        width: 37.5%;
        height: 156px
    }
`;

const InfoTag = styled(Tag)`
    text-align: center;

    ${theme.spacing.mobile._16('margin-bottom')};
    ${theme.spacing.tablet._16('margin-bottom')};
    ${theme.spacing.desktop._24('margin-bottom')};
`;

const InfoText = styled(Text)`
    font-family: 'Tiempos';
    line-height: 1.62em;
    text-align: center;
    font-size: 16px;

    ${above.tablet_s} {
        line-height: 1.41em;
        font-size: 20px;
    }
`;

const SearchInfoText = ({ tag, text }) => {
    return (
        <InfoTextWrapper>
            <InfoTag>{tag}</InfoTag>
            <InfoText>{text}</InfoText>
        </InfoTextWrapper>
    );
};

SearchInfoText.propTypes = {
    tag: PropTypes.string,
    text: PropTypes.string,
};

SearchInfoText.defaultProps = {
    tag: '',
    text: '',
};

export default SearchInfoText;
