import React from 'react';
import { below } from 'src/components/global/mediaqueries';
import Button from 'src/components/Button';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledButton = styled(Button)`
    ${below.tablet_s} {
        padding: 10px 8px!important;
        width: 100%;
        text-align: center;
    }
`;

const ProductCardButton = ({ children, ...rest }) => {
    return <StyledButton small {...rest}>{children}</StyledButton>;
};

ProductCardButton.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

ProductCardButton.defaultProps = {
    children: null,
};

export default ProductCardButton;
