import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import PropTypes from 'prop-types';
import BergStarSVG from 'src/icons/BergStar';
import theme from 'src/styles/theme';
import styled from '@emotion/styled';

const SvgWrapper = styled('div')`
    background-color: ${theme.color.productcard};
    width: 100%;
    position: relative;

    &:after{
        display: block;
        content: "";
        width: 100%;
        padding-bottom: 60%;
        position: relative;
    }

    .star {
        width: 50%;
        height: 30%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }
`;


const Image = ({
    alt,
    backgroundColor,
    className,
    crossOrigin,
    draggable,
    durationFadeIn,
    fadeIn,
    fixed,
    fluid,
    imgStyle,
    itemProp,
    loading,
    objectFit,
    objectPosition,
    onError,
    onLoad,
    onStartLoad,
    placeholderClassName,
    placeholderStyle,
    style,
    tag,
    title,
    ...rest
}) => {
    return (
        (!!fluid || !!fixed) ?
            <Img
                alt={alt}
                backgroundColor={backgroundColor}
                className={className}
                crossOrigin={crossOrigin}
                draggable={draggable}
                durationFadeIn={durationFadeIn}
                fadeIn={fadeIn}
                fixed={fixed}
                fluid={fluid && fluid.small ? fluid.small[0] : fluid}
                imgStyle={imgStyle}
                itemProp={itemProp}
                loading={loading}
                objectFit={objectFit}
                objectPosition={objectPosition}
                placeholderClassName={placeholderClassName}
                placeholderStyle={placeholderStyle}
                style={style}
                tag={tag}
                title={title}
                onError={onError}
                onLoad={onLoad}
                onStartLoad={onStartLoad}
                {...rest}
            />
        :
        <SvgWrapper>
            <BergStarSVG className="star"/>
        </SvgWrapper>
    );
};

// Read here for more information about gatsby-image: https://www.gatsbyjs.com/plugins/gatsby-image/#gatsby-image-props
Image.propTypes = {
    alt: PropTypes.string, // Passed to the img element. Defaults to an empty string alt=""
    backgroundColor: PropTypes.oneOfType([
        // Set a colored background placeholder. If true, uses “lightgray” for the color.
        PropTypes.string,
        PropTypes.bool,
    ]),
    className: PropTypes.string, //Passed to the wrapper element.
    crossOrigin: PropTypes.string, // Passed to the img element
    draggable: PropTypes.bool, // Set img tag draggable true/false,
    durationFadeIn: PropTypes.number, // fading duration is set up to 500ms by default
    fadeIn: PropTypes.bool, // Use fade in transition true/false
    fixed: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.arrayOf(PropTypes.object)]),
    fluid: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.arrayOf(PropTypes.object)]),
    imgStyle: PropTypes.object, // Spread into the default styles of the actual img element
    itemProp: PropTypes.string, // Add an itemprop schema.org structured data attribute on the image.
    loading: PropTypes.string, // lazy/eager/auto (defaults to lazy)
    objectFit: PropTypes.string, // Default to cover, use this, not CSS for IE11 compat
    objectPosition: PropTypes.string, // Defaults to 50% 50%, needed for object-fit cover IE
    onError: PropTypes.func, // A callback that is called when the image fails to load.
    onLoad: PropTypes.func, // A callback that is called when the full-size image has loaded.
    onStartLoad: PropTypes.func, // A callback that is called when the full-size image starts loading, it gets the parameter { wasCached: boolean } provided.
    placeholderClassName: PropTypes.string, // A class that is passed to the placeholder img element
    placeholderStyle: PropTypes.object, // Spread into the default styles of the placeholder img element
    style: PropTypes.object, // Spread into the default styles of the wrapper element
    tag: PropTypes.string, // Which HTML tag to use for wrapping elements. Defaults to div.
    title: PropTypes.string, // Passed to the img element
};

Image.defaultProps = {
    alt: null,
    backgroundColor: null,
    className: null,
    crossOrigin: null,
    draggable: null,
    durationFadeIn: null,
    fadeIn: null,
    fixed: null,
    fluid: null,
    imgStyle: null,
    itemProp: null,
    loading: null,
    objectFit: 'cover',
    objectPosition: '50% 50%',
    onError: null,
    onLoad: null,
    onStartLoad: null,
    placeholderClassName: null,
    placeholderStyle: null,
    style: null,
    tag: null,
    title: null,
};

export default Image;
