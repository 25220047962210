import { del } from 'src/libs/BergCommerceAPI/util';
import { getBasketId } from './Cookies';
import PropTypes from 'prop-types';

const RemoveFromCart = async ({cartItemKey}) => {
    const basketId = await getBasketId();

    return await del(`/item?cart_item_key=${cartItemKey}&cart_key=${basketId}`, {});
};

RemoveFromCart.PropTypes = {
    cartItemKey: PropTypes.string.isRequired,
};

export default RemoveFromCart;