import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';

const StyledBlockquote = styled('blockquote')`
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    line-height: 1.5em;
    font-style: italic;

    ${theme.fontSize.sans.mobile._24};
    ${theme.fontSize.sans.tablet._24};
    ${theme.fontSize.sans.desktop._24};

    ${theme.spacing.mobile._24('margin-top')};
    ${theme.spacing.tablet._24('margin-top')};
    ${theme.spacing.desktop._24('margin-top')};

    ${theme.spacing.mobile._24('margin-bottom')};
    ${theme.spacing.tablet._24('margin-bottom')};
    ${theme.spacing.desktop._24('margin-bottom')};
`;

const BlockQuote = ({ children }) => <StyledBlockquote>{children}</StyledBlockquote>;

BlockQuote.propTypes = {
    children: PropTypes.node.isRequired,
};

export default BlockQuote;