import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';

const Wrapper = styled('div')`
    position: relative;
    width: 30px;
    height: 30px;

    &:hover {
        div {
            &::after,
            &::before {
                width: 50%;
            }
        }
    }

    div {
        height: 1px;
        width: 21px;
        position: absolute;
        top: 15px;
        left: 4px;
        transform-origin: top left;

        &.vertical {
            transform: translate(11px, -10px) rotate(90deg);
            transition: width 0.2s, transform 0.2s;
        }

        &::before,
        &::after {
            content: '';
            background-color: ${theme.color.black};
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 38%;
            display: block;
            transition: width 0.2s;
        }

        &::after {
            left: auto;
            right: 0;
        }
    }

    &.active {
        div.horizontal {
            &::before, &::after {
                width: 50%;
            }
        }
        div.vertical {
            transform: translate(11px, 0) rotate(90deg);
            width: 0;
        }
    }
`;

const PlusMinusButton = ({ active, onClick }) => {
    return (
        <Wrapper className={`${active ? 'active' : ''}`} onClick={onClick}>
            <div className="horizontal" />
            <div className="vertical" />
        </Wrapper>
    );
};

export default PlusMinusButton;

PlusMinusButton.propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func,
};

PlusMinusButton.defaultProps = {
    active: false,
    onClick: null,
};
