import React from 'react';
import { below } from 'src/components/global/mediaqueries';
import styled from '@emotion/styled';
import Tag from 'src/components/typography/Tag';
import theme from 'src/styles/theme';

const ProductListingHeader = styled('div')`
    border-top: 1px solid ${theme.color.black};
    border-bottom: 1px solid ${theme.color.black};
    padding: 12px 0;
    display: flex;
    padding-left: 122px;

    ${below.tablet_s}{
        padding: 0;
        border-top: none;
    }
`;

const StyledTag = styled(Tag)`
    font-weight: 500;
    color: ${theme.color.black};

    ${below.tablet_s}{
        display: none;
    }
`;

const HeaderTagsWrapper = styled('div')`
    display: flex;
    margin-left: auto;
    width: 300px;
    justify-content: space-between;

    ${below.tablet_s}{
        width: 100%;
    }
`;

const ProductListingHeading = () => {
    return(
        <ProductListingHeader>
            <StyledTag>Title</StyledTag>
            <HeaderTagsWrapper>
                <StyledTag>Format</StyledTag>
                <StyledTag>Price</StyledTag>
            </HeaderTagsWrapper>
        </ProductListingHeader>
    );
};

export default ProductListingHeading;