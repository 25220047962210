import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const Heading = styled('h2')`
    margin: 0;
    padding: 0;
    font-weight: 400;
`;

Heading.propTypes = {
    as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    children: PropTypes.node.isRequired,
};

Heading.defaultProps = {
    as: 'h2',
};

export default Heading;
