import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import UnorderedListItem from './UnorderedListItem';
import { below } from 'src/components/global/mediaqueries';

const List = styled('ul')`
    padding: 0;
    margin: 0;
    list-style: none;

    ${below.tablet_s} {
        padding-left: 30px;
    }
`;

const UnorderedList = ({ children }) => {
    return (
        <List>
            {Array.isArray(children) ? children.map((item, index) => {
                return <UnorderedListItem key={index}>{item.props.children}</UnorderedListItem>;
            }) : <UnorderedListItem>{children.props.children}</UnorderedListItem> }
        </List>
    );
};

UnorderedList.propTypes = {
    children: PropTypes.node.isRequired,
};

export default UnorderedList;
