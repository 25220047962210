import React, {useMemo} from 'react';
import { graphql } from 'gatsby';
import DataLayer from 'src/components/tracking/DataLayer';
import htmlDecode from 'src/utils/htmlDecode';
import inBrowser from 'src/utils/inBrowser';
import Layout from 'src/components/layout';
import Modules from 'src/load-module';
import PropTypes from 'prop-types';

const MainTemplate = ({ data, location, pageContext: { site, options, template, isPost, isCheckout, categoryName, metaTitle, isAnalyst, analystId, mainCategoryId, isProduct, ...rest } }) => {
    const renderTemplate = useMemo(() => {
        return (
            <Layout title={site[0].site_name} metaTitle={htmlDecode(metaTitle)} options={options}>
                {React.createElement(Modules(template), {
                    data,
                    options,
                    location,
                    ...rest,
                })}
            </Layout>
        );
    }, [location]);

    // This is used for GA tracking.
    if (inBrowser) {   
        DataLayer.track({
            event: 'page_view',
            'page_title': htmlDecode(metaTitle) ? htmlDecode(metaTitle) : '404',
            'page_link': location.href,
        });
    }

    return(
        !isCheckout ?
        <>
            {renderTemplate}
        </>
        : React.createElement(Modules(template), {
            options,
            metaTitle: 'checkout',
            ...rest,
        })
    );
};

export const query = graphql`
    query PageSpecificDataQueries($mainCategoryId: Int, $isAnalyst:Boolean = false, $analystId: Int, $categoryName: String, $isPost:Boolean = false, $isProduct:Boolean = false) {
        allCmsApiPosts(
            limit: 4
            sort: { order: DESC, fields: post_date }
            filter: { categories: { elemMatch: { name: { eq: $categoryName } } } }
        ) @include(if:$isPost) {
            edges {
                node {
                    id
                    acf_data
                    post_title
                    post_date
                    categories {
                        link
                        name
                    }
                }
            }
        }
        relatedPosts: allCmsApiPosts(
            limit: 4
            sort: { order: DESC, fields: post_date }
            filter: {author_ids: {eq: $analystId}, categories: { elemMatch: { name: { eq: "Blog" } } }}
        ) @include(if:$isAnalyst) {
            edges {
                node {
                    id
                    acf_data
                    author_ids
                    post_title
                    post_date
                    categories {
                        link
                        name
                    }
                }
            }
        }
        analystRelatedProducts: allCmsApiProducts(
            limit: 4
            sort: { order: DESC, fields: published }
            filter: {analysts: {elemMatch: {analyst: {alternative_id: {eq: $analystId}}}}}
        ) @include(if:$isAnalyst) {
            edges {
                node {
                    id
                    name
                    main_category_name
                    edition
                    acf_data
                    alternative_id
                    prices
                    analysts {
                        analyst {
                            alternative_id
                        }
                    }
                    fields {
                        productImage {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 1000, quality: 60) {
                                        aspectRatio
                                        base64
                                        originalImg
                                        sizes
                                        src
                                        srcSet
                                        srcWebp
                                        srcSetWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        mainCategory: allCmsApiCategories(
            filter: {cat_ID: {eq: $mainCategoryId}}
        ) @include(if:$isProduct) {
            edges {
                node {
                    id
                    name
                    cat_ID
                    acf_data
                }
            }
        }
    }
`;

export default MainTemplate;

MainTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object,
    pageContext: PropTypes.shape({
        allImages: PropTypes.arrayOf(PropTypes.object),
        analystId: PropTypes.number,
        categoryName: PropTypes.string,
        isCheckout: PropTypes.bool,
        isAnalyst: PropTypes.bool,
        isPost: PropTypes.bool,
        isProduct: PropTypes.bool,
        metaTitle: PropTypes.string.isRequired,
        options: PropTypes.object.isRequired,
        page: PropTypes.object,
        site: PropTypes.arrayOf(PropTypes.object).isRequired,
        mainCategoryId: PropTypes.number,
        template: PropTypes.string.isRequired,
    })
};

MainTemplate.defaultProps = {
    location: {},
    pageContext: {
        allImages: {},
        analystId: null,
        isAnalyst: false,
        isPost: false,
        isCheckout: false,
        page: {},
    },
};
