/* eslint-disable camelcase */

class DataLayer {
    constructor() {
        this.interval_handler = null;
        this.queue = [];
    }

    handler() {
        if (this.interval_handler === null) {
            const self = this;
            this.interval_handler = setInterval(() => {
                if (self.isLoaded()) {
                    clearInterval(self.interval_handler);
                    self.processQueue();
                }
            }, 100);
        }
    }

    track(data = {}) {
        if (window.google_tag_manager === undefined) {
            this.queue.push(() => this.process(data));
            this.handler();
        } else {
            this.process(data);
        }
    }

    process(data) {
        try {
            window.dataLayer.push(data);
        } catch (e) {
            console.error('gtm error');
        }
    }

    processQueue() {
        while (this.queue.length > 0) {
            this.queue.shift()();
        }
    }

    isLoaded() {
        return window.google_tag_manager !== undefined;
    }

    reset() {
        const gtmTrackingKey = process.env.REACT_APP_GTM_TRACKING_KEY;

        if (window.google_tag_manager === undefined) {
            this.queue.push(() => {
                window.google_tag_manager[gtmTrackingKey].dataLayer.reset();
            });
            this.handler();
        } else {
            window.google_tag_manager[gtmTrackingKey].dataLayer.reset();
        }
    }

}

const instance = new DataLayer();

export default instance;