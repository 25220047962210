import React, { useState, useContext } from 'react';
import CartContext from 'src/libs/BergCommerceAPI/Basket/Context/Cart';
import ItemCount from 'src/libs/BergCommerceAPI/Basket/ItemCount';
import LoadingIndicator from 'src/components/LoadingIndicator';
import PropTypes from 'prop-types';
import RemoveFromCart from 'src/libs/BergCommerceAPI/Basket/RemoveFromCart';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';

const RemoveButton = styled('span')`
    display: block;
    text-decoration: underline;
    color: ${theme.color.black};
    margin-top: auto;
    cursor: pointer;
    font-size: 12px;
`;

const RemoveFromCartButton = ({cartItemKey, quantity, buttonText, onSuccess}) => {
    const [loading, setLoading] = useState(false);
    const {setCart} = useContext(CartContext);

    const onClickAction = async ({ cartItemKey, quantity }) => {
        setLoading(true);
        const result = await RemoveFromCart({cartItemKey, quantity});
        setLoading(false);
        setCart({
            count: await ItemCount(),
        });
        onSuccess();
        return result;
    };
    return (
        <RemoveButton
            onClick={ () => onClickAction(
                { cartItemKey,
                  quantity,
                }
            )}
        >
            <div style={{display: 'flex'}}>
                <span>
                    {buttonText}
                </span>
                { !loading ?
                    null
                  :
                    <LoadingIndicator spinnerStyle={{height: '20px', width: '20px', color: 'currentcolor'}}/>
                }
            </div>
        </RemoveButton>
    );
};

RemoveFromCartButton.propTypes = {
    buttonText: PropTypes.string,
    cartItemKey: PropTypes.string,
    onSuccess: PropTypes.func,
    quantity: PropTypes.number,
};

RemoveFromCartButton.defaultProps = {
    buttonText: '',
    cartItemKey: '',
    onSuccess: null,
    quantity: 1,
};

export default RemoveFromCartButton;