import React from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import { Below } from 'src/components/Breakpoints';
import FooterSection from './FooterSection';
import Link from 'src/components/Link';
import Newsletter from './Newsletter';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';

const FooterWrapper = styled('div')`
    color: ${theme.color.black};
    background-color: ${theme.color.footer};
    border-top: 1px solid ${theme.color.black};
`;


const FooterSections = styled('div')`
    display: flex;
    ${above.tablet_s} {
        border-bottom: 1px solid ${theme.color.black};
    }

    .footercolumn {
        flex: 1;

        + .footercolumn {
            border-left: 1px solid ${theme.color.black};
        }
    }

    ${below.tablet_s} {
        display: block;
        .footercolumn {
            border-left: none !important;
        }
    }
`;

const FooterBottom = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${below.tablet_s} {
        flex-direction: row-reverse;
    }

    ${theme.spacing.mobile._10('padding')};
    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.mobile._12('padding-right')};

    ${theme.spacing.tablet._16('padding')};
    ${theme.spacing.tablet._32('padding-left')};
    ${theme.spacing.tablet._32('padding-right')};

    ${theme.spacing.desktop._16('padding')};
    ${theme.spacing.desktop._32('padding-left')};
    ${theme.spacing.desktop._32('padding-right')};
`;

const IconSectionSocial = styled('div')`
    flex: 1;
    text-align: right;

    a + a {
        ${theme.spacing.mobile._8('margin-left')};
        ${theme.spacing.tablet._8('margin-left')};
        ${theme.spacing.desktop._8('margin-left')};
    }

    ${below.tablet_s} {
        display: none;
    }
`;

const IconSectionPayment = styled('div')`
    flex: 1;
    text-align: left;

    a + a {
        ${theme.spacing.mobile._8('margin-left')};
        ${theme.spacing.tablet._8('margin-left')};
        ${theme.spacing.desktop._8('margin-left')};
    }

    ${below.tablet_s} {
        text-align: right;

        a + a {
            ${theme.spacing.mobile._8('margin-left')};
            ${theme.spacing.tablet._8('margin-left')};
            ${theme.spacing.desktop._8('margin-left')};
        }
    }
`;

const Copyright = styled('div')`
    font-size: 12px;
`;

const Footer = ({data}) => {
    const {
        copyrightMessage,
        menu,
        newsletter,
        socialNetworks,
        paymentMethods,
    } = data;

    return (
        <FooterWrapper>
            <Newsletter data={newsletter} />
            <FooterSections>
                {menu && menu.map((footerMenu, index) => {
                    return (
                        <FooterSection column={index} menuData={footerMenu} key={index} />
                    );
                })}
                <Below breakpoint="tablet_s">
                    <FooterSection
                        menuData={{
                            heading: 'Social',
                            links: socialNetworks.map((socialNetwork) => {
                                return(
                                    {
                                        link: {
                                            text: socialNetwork.name,
                                            url: socialNetwork.url
                                        }
                                    }
                                );
                            }),
                        }}
                    />
                </Below>
            </FooterSections>
            <FooterBottom>
                <IconSectionPayment>
                    {paymentMethods && paymentMethods.map((icon, index) => {
                        return (
                            <Link src={icon.url} key={index} >
                                <img src={icon.icon} alt={icon.name} />
                            </Link>
                            );
                        })}
                </IconSectionPayment>
                <Copyright>
                    {copyrightMessage}
                </Copyright>
                <IconSectionSocial>
                    {socialNetworks && socialNetworks.map((icon, index) => {
                        return (
                            <Link src={icon.url} key={index} >
                                <img src={icon.icon} alt={icon.name} />
                            </Link>
                        );
                    })}
                </IconSectionSocial>
            </FooterBottom>
        </FooterWrapper>
    );
};

Footer.propTypes = {
    data: PropTypes.shape({
        copyrightMessage: PropTypes.string,
        menu: PropTypes.array,
        newsletter: PropTypes.object,
        paymentMethods: PropTypes.array,
        socialNetworks: PropTypes.array,
    })
};

Footer.defaultProps = {
    data: {
        copyrightMessage: '',
        menu: [],
        newsletter: {},
        paymentMethods: [],
        socialNetworks: [],
    }
};

export default Footer;
