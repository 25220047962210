import React from 'react';
import { above } from 'src/components/global/mediaqueries';
import PropTypes from 'prop-types';
import RemoveFromCartButton from 'src/components/Header/CartPopup/RemoveFromCartButton';
import styled from '@emotion/styled';
import Tag from 'src/components/typography/Tag';
import theme from 'src/styles/theme';

const TitleText = styled('span')`
    display: block;
    font-size: 16px;
    line-height: 1.4;
    color: ${theme.color.black};
    width: 100%;
    margin-bottom: auto;
`;

const TextWrapper = styled('div')`
    display: grid;
    margin: 8px 0 8px 16px;

    ${above.tablet_s}{
        margin: 0;
        padding: 16px 0 16px 24px;
        width: 250px;
    }
`;

const InfoSection = styled('div')`
    display: flex;
    justify-content: space-between;
    border-top: 1px solid ${theme.color.black};
    border-bottom: 1px solid ${theme.color.black};
    color: ${theme.color.black};

    margin-top: 12px;
    padding: 12px;

    ${above.tablet_s}{
        flex: 0 0 300px;
        border: none;
        margin: 0;
        padding: 0;
        margin-top: 22px;
        width: 300px;
    }

    padding-left: 0;
    padding-right: 0;
`;

const TagWrapper = styled('div')`
    display: flex;
`;

const StyledTag = styled(Tag)`
    font-weight: 500;
    white-space: nowrap;

    ${above.tablet_s}{
        &.hideInDesktop {
            display: none;
        }
    }
`;

const PriceText = styled('span')`
    line-height: 1;
    font-size: 12px;
    font-weight: 500;
    margin-left: 16px;
    white-space: nowrap;
`;

const VariationText = styled('span')`
    margin-left: 5px;
    line-height: 1;
    font-size: 12px;
    white-space: nowrap;
`;

const CartProductCardWrapper = styled('div')`
    padding: 12px 0 12px 8px;

    ${above.tablet_s}{
        display: flex;
        align-items: flex-start;
        & + & {
            border-top: 1px solid ${theme.color.black};
            border-bottom: 1px solid ${theme.color.black};
        }
    }
`;

const MainCardWrapper = styled('div')`
    display: flex;

    ${above.tablet_s}{
        margin-right: 24px;
    }
`;

const StyledImage = styled('img')`
    width: 76px;
    height: 109px;

    ${above.tablet_s} {
        width: 90px;
        height: 129px;
    }
`;

const CartProductCard = ({product, forceUpdate, price, licenseText}) => {
    const cartItemKey = product?.key || '';
    const image = `${process.env.MEDIA_UPLOAD}/thumbnails/${product?.product_id}.png`;
    const title = product?.product_title || '';

    return (
        <CartProductCardWrapper>
            <MainCardWrapper>
                <StyledImage alt="" src={image} />
                <TextWrapper>
                    <TitleText>{title}</TitleText>
                    <RemoveFromCartButton
                        cartItemKey={cartItemKey}
                        buttonText="Remove"
                        onSuccess={forceUpdate}
                    />
                </TextWrapper>
            </MainCardWrapper>
            <InfoSection>
                <TagWrapper>
                    <StyledTag>PDF & Excel:</StyledTag>
                    <VariationText>{licenseText}</VariationText>
                </TagWrapper>
                <TagWrapper>
                    <StyledTag className="hideInDesktop">Price:</StyledTag>
                    <PriceText>{price}</PriceText>
                </TagWrapper>
            </InfoSection>
        </CartProductCardWrapper>
    );
};

CartProductCard.propTypes = {
    forceUpdate: PropTypes.func,
    licenseText: PropTypes.string,
    price: PropTypes.string,
    product: PropTypes.shape({
        key: PropTypes.string,
        'product_image': PropTypes.string,
        'product_id': PropTypes.number,
        'product_title': PropTypes.string,
    }),
};

CartProductCard.defaultProps = {
    forceUpdate: null,
    licenseText: '',
    price: '',
    product: {
        key: '',
        'product_image': '',
        'product_id': null,
        'product_title': '',
    },
};

export default CartProductCard;
