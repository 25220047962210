import inBrowser from 'src/utils/inBrowser';

const htmlDecodeFallback = (encodedString) => {
    const translateRe = /&(nbsp|amp|quot|lt|gt);/g;
    const translate = {
        'nbsp': ' ',
        'amp': '&',
        'quot': '"',
        'lt': '<',
        'gt': '>'
    };
    return encodedString.replace(translateRe, (match, entity) => {
        return translate[entity];
    }).replace(/&#(\d+);/gi, (match, numStr) => {
        const num = parseInt(numStr, 10);
        return String.fromCharCode(num);
    });
};

const htmlDecode = (text) => {
    if (inBrowser) {
        const htmlElement = document.createElement('textarea');
        htmlElement.innerHTML = text;
        // handle case of empty input
        return htmlElement.childNodes.length === 0 ? '' : htmlElement.childNodes[0].nodeValue;
    }
 
    return htmlDecodeFallback(text);
};

export default htmlDecode;