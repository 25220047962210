import React, { useContext } from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import BergStar from 'src/icons/BergStar';
import Heading from 'src/components/Heading';
import htmlDecode from 'src/utils/htmlDecode';
import Image from 'src/components/Image';
import Link from 'src/components/Link';
import ProductCardButton from 'src/components/buttons/ProductCardButton';
import PropTypes from 'prop-types';
import SearchContext from 'src/components/Search/Context/Search';
import styled from '@emotion/styled';
import Tag from 'src/components/typography/Tag';
import theme from 'src/styles/theme';

const CardWrapper = styled(Link)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: ${theme.color.black};
    border-bottom: 1px solid ${theme.color.black};
`;

const Header = styled('div')`
    background-color: ${theme.color.productcard};
    position: relative;

    ${theme.spacing.mobile._16('margin-bottom')};
    ${theme.spacing.tablet._16('margin-bottom')};
    ${theme.spacing.desktop._20('margin-bottom')};

    &:after {
        content: '';
        width: 100%;
        padding-top: 125%;
        display: block;
    }
`;

const HeaderTag = styled('div')`
    position: absolute;
    text-transform: none;
    font-family: 'SuisseIntL';
    font-size: 12px;
    line-height: 1;

    ${below.mobile_l} {
        display: none;
    }

    ${theme.spacing.mobile._8('top')};
    ${theme.spacing.mobile._8('left')};

    ${theme.spacing.tablet._12('top')};
    ${theme.spacing.tablet._12('left')};

    ${theme.spacing.desktop._16('top')};
    ${theme.spacing.desktop._16('left')};
`;

const Cover = styled('div')`
    background-color: ${theme.color.brown};
    width: 60%;

    ${below.tablet_l} {
        width: 66%;
    }

    ${below.mobile_l} {
        width: 61%;
    }

    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const LogoWrapper = styled('div')`
    width: 74px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${above.tablet_s} {
        width: 100%;
        ${theme.spacing.tablet._88('padding')};
        ${theme.spacing.desktop._120('padding')};

        & > div {
            ${theme.spacing.desktop._12('padding')};
        }
    }
`;

const Category = styled(Tag)`
    ${theme.spacing.mobile._12('margin-bottom')};
    ${theme.spacing.tablet._12('margin-bottom')};
    ${theme.spacing.desktop._12('margin-bottom')};
`;

const CardHeading = styled(Heading)`
    font-size: 16px;
    line-height: 1.35em;
`;

const CardFooter = styled('div')`
    align-self: flex-end;
    width: 100%;
    margin-top: auto;

    ${theme.spacing.mobile._24('padding-bottom')};
    ${theme.spacing.tablet._24('padding-bottom')};
    ${theme.spacing.desktop._20('padding-bottom')};
`;

const StyledButton = styled(ProductCardButton)`
    ${theme.spacing.mobile._12('margin-top')};
    ${theme.spacing.tablet._16('margin-top')};
    ${theme.spacing.desktop._16('margin-top')};

    ${above.desktop_s} {
        display: none;
    }
`;

const ProductCard = ({ tag, category, image, heading, link }) => {
    const toggleSearch = useContext(SearchContext);

    return (
        <CardWrapper src={link} onClick={toggleSearch}>
            <Header>
                <HeaderTag>{tag}</HeaderTag>
                {image ? (
                    <Cover>
                        <Image fluid={image.src.small} />
                    </Cover>
                ) : (
                    <LogoWrapper>
                        <div>
                            <BergStar style={{ width: '100%', height: '100%' }} />
                        </div>
                    </LogoWrapper>
                )}
            </Header>
            <Category>{htmlDecode(category)}</Category>
            <CardHeading>{heading}</CardHeading>
            <CardFooter>
                <StyledButton small>Read more and order</StyledButton>
            </CardFooter>
        </CardWrapper>
    );
};

ProductCard.propTypes = {
    category: PropTypes.string,
    heading: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.arrayOf(PropTypes.object)]),
    link: PropTypes.string,
    tag: PropTypes.string,
};

ProductCard.defaultProps = {
    category: '',
    heading: '',
    image: '',
    link: '',
    tag: '',
};

export default ProductCard;
