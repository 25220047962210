import React from 'react';
import Circle from 'src/icons/base/Circle';
import Line from 'src/icons/base/Line';
import PropTypes from 'prop-types';
import SVG from 'src/icons/base/SVG';
import theme from 'src/styles/theme';

const SearchIcon = ({ iconColor, ...rest }) => (
    <SVG fill="none" {...rest}>
        <Circle cx="6.1106" cy="6.1106" r="5.4606" stroke={iconColor} strokeWidth="1.3"/>
        <Line x1="10.8004" y1="9.88145" x2="13.4594" y2="12.5404" stroke={iconColor} strokeWidth="1.3"/>
    </SVG>
);

SearchIcon.propTypes = {
    height: PropTypes.string,
    iconColor: PropTypes.string,
    viewBox: PropTypes.string,
    width: PropTypes.string,
};

SearchIcon.defaultProps = {
    height: '13px',
    iconColor: theme.color.black,
    viewBox: '0 0 13 13',
    width: '13px',
};

export default SearchIcon;
