import BlockQuote from './BlockQuote';
import Bold from './Bold';
import HeadingLevelThree from './HeadingLevelThree';
import HeadingLevelFour from './HeadingLevelFour';
import Italic from './Italic';
import ListItem from './ListItem';
import OrderedList from './OrderedList';
import PropTypes from 'prop-types';
import React from 'react';
import Underline from './Underline';
import UnorderedList from './UnorderedList';
import Url from './Url';
import WysiwygContainer from 'src/containers/WysiwygContainer';
import styled from '@emotion/styled';

const Wrapper = styled('div')`
    // Selects ul and ol.
    ul, ol {
        // Selects all ul & ol proceeded by any element.
        * + & {
            margin-top: 1em;
        }
        // Selects anything proceeded by ul or ol.
        & + * {
            margin-top: 1em;
        }
    }
`;

const WysiwygWrapper = ({ data, validTags, tagComponents, textComponent, regularLinks, openLinksInNewTab}) => {
    const components = {
        url: Url,
        i: Italic,
        b: Bold,
        u: Underline,
        ul: UnorderedList,
        ol: OrderedList,
        li: ListItem,
        quote: BlockQuote,
        h3: HeadingLevelThree,
        h4: HeadingLevelFour,
        ...tagComponents,
    };

    const validComponents = {};
    validTags.forEach(tag => {
        const tagComponent = components[tag];
        if (tagComponent) {
            validComponents[tag] = components[tag];
        }
    });

// TODO: remove wrapper and set line height with textComponent where WysiwygWrapper is imported.
    return (
        <Wrapper>
            <WysiwygContainer data={data} textComponent={textComponent} tagComponents={validComponents} regularLinks={regularLinks} openLinksInNewTab={openLinksInNewTab} />
        </Wrapper>
    );
};

WysiwygWrapper.propTypes = {
    data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    openLinksInNewTab: PropTypes.bool,
    regularLinks: PropTypes.bool,
    tagComponents: PropTypes.object,
    textComponent: PropTypes.elementType,
    validTags: PropTypes.array,
};

WysiwygWrapper.defaultProps = {
    openLinksInNewTab: false,
    regularLinks: false,
    tagComponents: {},
    // eslint-disable-next-line react/prop-types
    textComponent: ({ children }) => <p>{children}</p>,
    validTags: ['url', 'i', 'b', 'u', 'ul', 'ol', 'li', 'quote', 'h3', 'h4'],
};

export default WysiwygWrapper;
