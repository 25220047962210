import Link from 'src/components/Link';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

const StyledLink = styled(Link)`
	color: inherit;
`;

const Url = ({ attrs, children, regularLinks, openLinksInNewTab }) =>
    !regularLinks && !openLinksInNewTab?
        <StyledLink src={attrs['dataUrl']}>
            {children}
        </StyledLink>
    : openLinksInNewTab ?
        <a href={attrs['dataUrl']} rel="noreferrer" target="_blank">{children}</a>
    :
        <a href={attrs['dataUrl']}>{children}</a>;

Url.propTypes = {
    attrs: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    openLinksInNewTab: PropTypes.bool.isRequired,
    regularLinks: PropTypes.bool.isRequired,
};

export default Url;
