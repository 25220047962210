import React, { useContext } from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import { useStaticQuery, graphql } from 'gatsby';
import htmlDecode from 'src/utils/htmlDecode';
import Link from 'src/components/Link';
import SearchContext from 'src/components/Search/Context/Search';
import styled from '@emotion/styled';
import Tag from 'src/components/typography/Tag';
import theme from 'src/styles/theme';

const CategoriesTextWrapper = styled('div')`
    color: ${theme.color.black};
    width: 100%;

    ${below.tablet_s} {
        min-height: 185px;
        ${theme.spacing.mobile._40('padding-left')};
        ${theme.spacing.mobile._40('padding-right')};
    }

    ${above.tablet_s} {
        margin: 0 auto;
        width: 50%;
        height: 168px;
    }

    ${above.desktop_s} {
        width: 37.5%;
        height: 156px;
    }
`;

const CategoriesTag = styled(Tag)`
    text-align: center;

    ${theme.spacing.mobile._16('margin-bottom')};
    ${theme.spacing.tablet._16('margin-bottom')};
    ${theme.spacing.desktop._24('margin-bottom')};
`;

const LinkCategory = styled(Link)`
    font-family: 'Tiempos';
    line-height: 1.62em;
    text-align: center;
    font-size: 16px;
    color: ${theme.color.black};

    ${above.tablet_s} {
        line-height: 1.41em;
        font-size: 20px;
    }

    &::after {
        content: '/';
        margin-left: 5px;
        margin-right: 5px;
    }

    &:last-child {
        &::after {
            content: none;
        }
    }
`;

const CategoriesWrapper = styled('div')`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const SearchCategorySuggestions = ({ ...rest }) => {
    const { allCmsApiCategories } = useStaticQuery(
        graphql`
            query SearchCategorySuggestionsQuery {
                allCmsApiCategories(sort: { fields: count, order: DESC }, limit: 5, filter: { name: { ne: null } }) {
                    nodes {
                        name
                        acf_data
                    }
                }
            }
        `
    );

    const { nodes } = allCmsApiCategories;

    const toggleSearch = useContext(SearchContext);

    return (
        <CategoriesTextWrapper {...rest}>
            <CategoriesTag>Categories</CategoriesTag>
            <CategoriesWrapper>
                {nodes.map((node, index) => {
                    const { name, acf_data } = node;
                    const acfData = JSON.parse(acf_data);
                    const permalink = acfData.permalink;
                    return (
                        <LinkCategory key={index} src={permalink} onClick={toggleSearch}>
                            {htmlDecode(name)}
                        </LinkCategory>
                    );
                })}
            </CategoriesWrapper>
        </CategoriesTextWrapper>
    );
};

export default SearchCategorySuggestions;
