import React, {Fragment, useState, useEffect} from 'react';
import { below } from 'src/components/global/mediaqueries';
import { Helmet } from 'react-helmet';
import CartContext from 'src/libs/BergCommerceAPI/Basket/Context/Cart';
import CookieConsent from 'src/components/CookieConsent';
import fonts from 'src/assets/fonts/fonts';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import ItemCount from 'src/libs/BergCommerceAPI/Basket/ItemCount';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import header from 'src/cache/header';
import footer from 'src/cache/footer';

const AppStyles = styled(Fragment)`
    ${fonts};
    * {
      box-sizing: border-box;
    }
`;

const ModuleContainer = styled('div')`
    min-height: 90vh;
    animation: fadein 1s;

    ${below.tablet_s} {
        //This exists due to search input field is always visible in mobile mode
        padding-top: 50px;
    }

    @keyframes fadein {
        from { opacity: 0; }
        to   { opacity: 1; }
    }
`;

const Layout = ({ children, title, metaTitle, options }) => {
    const [cart, setCart] = useState({
        count: 0,
    });

    useEffect(() => {
        const fetchCartCount = async () => {
            setCart({
                count: await ItemCount(),
            });
        };
        fetchCartCount();
    }, []);

    return (
        <CartContext.Provider value={{ cart, setCart }} >
            <AppStyles>
                <Header data={header} siteTitle="Berg Insight" />
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{metaTitle}</title>
                </Helmet>
                <ModuleContainer id="modulecontainer">
                    {children}
                </ModuleContainer>
                <Footer data={footer} />
                <CookieConsent data={options.cookieSettings} />
            </AppStyles>
        </CartContext.Provider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    metaTitle: PropTypes.string,
    options: PropTypes.object,
    title: PropTypes.string,
};

Layout.defaultProps = {
    metaTitle: '',
    options: {},
    title: '',
};

export default Layout;