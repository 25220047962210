import PropTypes from 'prop-types';
import { breakpoints, breakpointsMax, labels } from 'src/components/Breakpoints/breakpointValues';

const breakpointLabels = labels;
const constBreakpointMinValues = breakpoints;
const constBreakpointMaxValues = breakpointsMax;

const breakpointMinMap = constBreakpointMinValues.reduce((arr, size, index) => {
    return [
        ...arr,
        {
            label: breakpointLabels[index],
            size,
        },
    ];
}, []);

const breakpointMaxMap = constBreakpointMaxValues.reduce((arr, size, index) => {
    return [
        ...arr,
        {
            label: breakpointLabels[index],
            size,
        },
    ];
}, []);

const findBreakpoint = (label, breakpointMap) => {
    return breakpointMap.find((bp) => bp.label === label);
};

const dynamicScaling = (minValue, maxValue, minBreakpoint, maxBreakpoint, unit='px') => {
    minValue = String(minValue).replace(/[^0-9.]/g, '');
    maxValue = String(maxValue).replace(/[^0-9.]/g, '');

    const minBreakpointWidth = findBreakpoint(minBreakpoint, breakpointMinMap);
    const maxBreakpointWidth = findBreakpoint(maxBreakpoint, breakpointMaxMap);

    const minWidth = minBreakpointWidth ? String(minBreakpointWidth.size).replace(/[^0-9.]/g, '') : '';
    const maxWidth = maxBreakpointWidth ? String(maxBreakpointWidth.size).replace(/[^0-9.]/g, '') : '';
    
    return `calc(${minValue}${unit} + (${maxValue} - ${minValue}) * ((100vw - ${minWidth}${unit}) / (${maxWidth} - ${minWidth})))`;
};

dynamicScaling.propTypes = {
    maxBreakpoint: PropTypes.oneOf(breakpointLabels),
    maxValue: PropTypes.number.isRequired,
    minBreakpoint: PropTypes.oneOf(breakpointLabels),
    minValue: PropTypes.number.isRequired,
    unit: PropTypes.oneOf(['px', 'em'])
};

dynamicScaling.defaultProps = {
    unit: 'px'
};

export default dynamicScaling;