import Cookies from 'js-cookie';
import GenerateBasketKey from './GenerateBasketKey';

const params = {
    guest: 'GuestCustomerId',
    woocommerce: 'CocartId',
};

const isValidId = (customerId) => {
    return customerId && /^[a-z,0-9]{32,33}/.test(customerId); // Allows only lowercase a-z & or numbers, (32-33 characters).
};

const getGuestBasketId = () => Cookies.get(params.guest);

const getWoocommerceCocartCookie = () => {
    //Cocart is the woocommerce plugin used for cart.

    const getAllCookies = Cookies.get();
    const cookieKeysArray = Object.keys( getAllCookies );
    const getCookieKey = cookieKeysArray.find(element => element.indexOf('wp_cocart_session') !== -1);

    return (
        Cookies.get( getCookieKey ) // Returns value of cocart cookie
    );
};

const transformCocartCookie = cocartCookie => {
    /*
    Cart Key/Customer ID
    Cart expiring timestamp.
    Cart expiration timestamp.
    Cart hash (Customer ID and Cart Expiration Timestamp encrypted).
    */
    const cookieArray = cocartCookie.split('||');
    const cookieObject = {
        key: cookieArray[0] || null,
        expiring: cookieArray[1] || null,
        expiration: cookieArray[2] || null,
        hash: cookieArray[3] || null,
    };
    return cookieObject;
};

const createIdIfMissing = async () => {
    // Adds id to guest cookies if missing.
    isValidId(getGuestBasketId()) // Causes lint error no-unused expression?
        ? null
        : Cookies.set(params.guest, await GenerateBasketKey(), { path: '/' });
};

const removeBasket = async () => {
    Cookies.remove(params.guest);
};

const getBasketId = async () => {

    const rawCocartCookie = getWoocommerceCocartCookie();
    const cocartCookieObj = typeof(rawCocartCookie) === 'string' ? transformCocartCookie(rawCocartCookie) : {};
    await createIdIfMissing('GeneratedBasketId');

    const chooseBasketId = cocartCookieObj.key || getGuestBasketId();

    return chooseBasketId;
};

export { getBasketId, getGuestBasketId, removeBasket };
