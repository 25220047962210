import { React, useContext } from 'react';
import Button from 'src/components/Button';
import Heading from 'src/components/Heading';
import Link from 'src/components/Link';
import PropTypes from 'prop-types';
import SearchContext from 'src/components/Search/Context/Search';
import styled from '@emotion/styled';
import Text from 'src/components/Text';
import theme from 'src/styles/theme';

const Wrapper = styled('div')`
    width: 100%;
    border-bottom: 1px solid ${theme.color.black};

    ${theme.spacing.mobile._10('padding-bottom')};
    ${theme.spacing.tablet._10('padding-bottom')};
    ${theme.spacing.desktop._10('padding-bottom')};
`;

const InnerWrapper = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 34px;
`;

const StyledHeading = styled(Heading)`
    font-family: 'SuisseIntL';
    font-style: normal;
    font-size: 24px;
    line-height: 100%;
    vertical-align: text-bottom;
    color: ${theme.color.black};
`;

const ButtonTextWrapper = styled('div')`
    display: flex;
    flex-direction: row;
`;

const ButtonText = styled('div')`
    margin: auto 0;
    font-size: 14px;
`;

const QuantityText = styled(Text)`
    margin-left: 4px;
    font-size: 10px;
`;

const SearchResultHeader = ({ title, buttonText, quantityText, className, query, category, ...rest }) => {
    const toggleSearch = useContext(SearchContext);

    return (
        <Wrapper className={className} {...rest}>
            <InnerWrapper>
                <StyledHeading as="h2">{title}</StyledHeading>
                {quantityText !== 0 && buttonText && (
                    <Link
                        src={`/search?category=${category}&term=${query}`}
                        state={{ /* passing empty state to make page re-render */ }}
                        onClick={toggleSearch}
                    >
                        <Button small outlined rounded>
                            <ButtonTextWrapper>
                                <ButtonText>{buttonText}</ButtonText>
                                <QuantityText>{quantityText}</QuantityText>
                            </ButtonTextWrapper>
                        </Button>
                    </Link>
                )}
                {quantityText === 0 && buttonText && (
                    <Button small outlined rounded disabled>
                        <ButtonTextWrapper>
                            <ButtonText>{buttonText}</ButtonText>
                            <QuantityText>{quantityText}</QuantityText>
                        </ButtonTextWrapper>
                    </Button>
                )}
            </InnerWrapper>
        </Wrapper>
    );
};

SearchResultHeader.propTypes = {
    buttonText: PropTypes.string,
    category: PropTypes.string,
    className: PropTypes.string,
    quantityText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    query: PropTypes.string,
    title: PropTypes.string,
};

SearchResultHeader.defaultProps = {
    buttonText: '',
    category: '',
    className: '',
    quantityText: '',
    query: '',
    title: '',
};

export default SearchResultHeader;
