import React, { useState, useContext, useEffect, useRef, memo } from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import algoliasearch from 'algoliasearch/lite';
import Arrow from 'src/icons/Arrow';
import { Configure, connectSearchBox, InstantSearch, Index, Stats } from 'react-instantsearch-dom';
import debounce from 'lodash/debounce';
import Link from 'src/components/Link';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import SearchCategorySuggestions from 'src/components/Search/SearchCategorySuggestions';
import SearchContext from 'src/components/Search/Context/Search';
import SearchIndices from 'src/components/Search/SearchIndices';
import SearchInfoText from 'src/components/Search/SearchInfoText';
import SearchResult from 'src/components/Search/SearchResult';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';

const StyledSearchRoot = styled('div')`
    position: fixed;
    width: 100%;
    max-height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    background-color: ${theme.color.white};
`;

const SearchBar = styled('div')`
    ${theme.spacing.tablet._88('padding-top')};
    ${theme.spacing.desktop._64('padding-top')};

    ${theme.spacing.tablet._40('padding-bottom')};
    ${theme.spacing.desktop._32('padding-bottom')};

    width: 50%;
    margin: 0 auto;

    ${below.tablet_s} {
        display: none;
    }
`;

const SearchBoxWrapper = styled('form')`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid black;
    z-index: 9999;

    ${below.tablet_s} {
        display: none;
    }
`;

const SearchInput = styled('input')`
    background: transparent;
    font-family: 'SuisseIntL';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    padding-left: 0px;
    color: ${theme.color.black};
    width: 100%;

    &::placeholder {
        color: ${theme.color.black};
    }

    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        line-height: 1.35em;
        color: ${theme.color.black};
        opacity: 1;
    }

    ${above.desktop_s} {
        font-size: 24px;

        &::placeholder {
            font-size: 24px;
        }
    }
`;

const StyledSearchCategorySuggestions = styled(SearchCategorySuggestions)`
    ${below.tablet_s} {
        ${theme.spacing.mobile._40('padding-top')};
        margin-top: 50px;
    }
`;

const SearchResultWrapper = ({ query, totalNbHits, children }) => {
    return totalNbHits > 0
        ? children
        : query && (
              <SearchInfoText
                  tag="sorry"
                  text="We couldn’t find what you are looking for. Please try with a different keyword."
              />
          );
};

const StartSearch = memo(
    ({ value, refine }) => refine(value),
    (nextProp, prevProp) => {
        return nextProp.value === prevProp.value;
    }
);

const HiddenSearchBox = connectSearchBox(StartSearch);

const Search = ({ mobileSearchString }) => {
    const [query, setQuery] = useState(null);
    const [nbHitsProducts, setNbHitsProducts] = useState(0);
    const [nbHitsPosts, setNbHitsPosts] = useState(0);
    const [nbHitsSuggestions, setNbHitsSuggestions] = useState(0);

    const toggleSearch = useContext(SearchContext);

    const ValidQuery = query !== undefined && query !== '' && (mobileSearchString !== null || query !== null);
    const searchClient = !ValidQuery
        ? { search: () => null }
        : algoliasearch(`${process.env.GATSBY_ALGOLIA_APP_ID}`, `${process.env.GATSBY_ALGOLIA_SEARCH_KEY}`);
    const totalNbHits = nbHitsProducts + nbHitsPosts + nbHitsSuggestions;
    const showResult = ValidQuery && totalNbHits > 0;

    const startSearch = debounce(searchString => {
        setQuery(searchString);
    }, 200);

    const inputRef = useRef(null);

    useEffect(() => {
        startSearch(mobileSearchString);
    }, [mobileSearchString]);

    useEffect(() => {
        inputRef && inputRef.current && inputRef.current.focus();
    });

    return (
        <StyledSearchRoot>
            <SearchBar>
                <SearchBoxWrapper
                    onSubmit={e => {
                        e.preventDefault();
                        const searchValue = e.target.children[0].value;
                        toggleSearch();
                        navigate(`/search?term=${searchValue}`);
                    }}
                >
                    <SearchInput
                        type="text"
                        placeholder="Search"
                        aria-label="Search"
                        maxLength={100}
                        ref={inputRef}
                        onChange={e => startSearch(e.target.value)}
                    />
                    <Link
                        src={`/search?term=${query === null ? '' : query}`}
                        state={{ /* passing empty state to make page re-render */ }}
                        onClick={toggleSearch}
                    >
                        <Arrow />
                    </Link>
                </SearchBoxWrapper>
            </SearchBar>

            <InstantSearch
                searchClient={searchClient}
                indexName={SearchIndices[0].name}
                onSearchStateChange={({ query }) => setQuery(query)}
            >
                <HiddenSearchBox value={query} />
                {SearchIndices.map(index => {
                    return (
                        <Index key={index} indexName={index.name}>
                            <Configure hitsPerPage={0} />
                            <Stats
                                translations={{
                                    stats(nbHits) {
                                        switch (index.name) {
                                            case `${process.env.ALGOLIA_PREFIX}Products`:
                                                setNbHitsProducts(nbHits);
                                                break;
                                            case `${process.env.ALGOLIA_PREFIX}Posts`:
                                                setNbHitsPosts(nbHits);
                                                break;
                                            case `${process.env.ALGOLIA_PREFIX}Posts_query_suggestions`:
                                                setNbHitsSuggestions(nbHits);
                                                break;
                                            default:
                                                break;
                                        }
                                    },
                                }}
                            />
                        </Index>
                    );
                })}
                <SearchResultWrapper query={query} totalNbHits={totalNbHits}>
                    <SearchResult
                        show={showResult}
                        query={query}
                        nbHitsSuggestions={nbHitsSuggestions || 0}
                        nbHitsProducts={nbHitsProducts || 0}
                        nbHitsPosts={nbHitsPosts || 0}
                    />
                </SearchResultWrapper>
                {!showResult && !ValidQuery && <StyledSearchCategorySuggestions />}
            </InstantSearch>
        </StyledSearchRoot>
    );
};

Search.propTypes = {
    mobileSearchString: PropTypes.string,
};

Search.defaultProps = {
    mobileSearchString: null,
};

SearchResultWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    query: PropTypes.string,
    totalNbHits: PropTypes.number.isRequired,
};

SearchResultWrapper.defaultProps = {
    query: null,
};

StartSearch.propTypes = {
    refine: PropTypes.func.isRequired,
    value: PropTypes.string,
};

StartSearch.defaultProps = {
    value: null,
};
export default Search;
