import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const NewLine = styled('p')`
    padding-top: 1em;
`;

export default class WysiwygContainer extends PureComponent {
    validTags = [];

    static propTypes = {
        data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
        openLinksInNewTab: PropTypes.bool,
        regularLinks: PropTypes.bool,
        tagComponents: PropTypes.object,
        textComponent: PropTypes.elementType,
    };

    // eslint-disable-next-line react/sort-comp
    static defaultProps = {
        openLinksInNewTab: false,
        regularLinks: false,
        tagComponents: {},
        textComponent: ({ children }) => <p style={{ margin: '0' }}>{children}</p>,
    };

    constructor(props) {
        super(props);
        this.validTags = Object.keys(props.tagComponents);
        this.regularLinks = false;
        this.openLinksInNewTab = false;
    }

    getComponent = tag => {
        if (this.validTags.indexOf(tag) !== -1) {
            const component = this.props.tagComponents[tag];
            return component ? component : null;
        }
        return null;
    };

    renderContent = (content, index, level = 0, renderedTags = [], subIndex = 0, regularLinks=false, openLinksInNewTab=false) => {
        let newContent = content;
        if (level === 0) {
            if (!renderedTags[index]) {
                renderedTags[index] = [];
            }
        }

        if (content !== undefined) {
            if (Array.isArray(content)) {
                // @TODO We might want to make the subIndex value more dynamic to handle deeper layers
                newContent = content.map((part, subIndex) =>
                    this.renderContent(part, index, level + 1, renderedTags, subIndex, this.props.regularLinks, this.props.openLinksInNewTab)
                );
            } else if (typeof content === 'object' || content === '') {
                const RenderComponent = this.getComponent(content.tag);
                if (RenderComponent) {
                    renderedTags[index].push(content.tag);
                    newContent = (
                        <RenderComponent regularLinks={regularLinks} openLinksInNewTab={openLinksInNewTab} attrs={content.attributes} key={`${index}.${level}.${subIndex}`}>
                            {this.renderContent(content.content, index, level + 1, renderedTags, null, this.props.regularLinks, this.props.openLinksInNewTab)}
                        </RenderComponent>
                    );
                } else if (content === ''){
                    // This allows a NewLine to be handled as "newContent" allowing NewLine support.
                    newContent = '';
                } else {
                    newContent = this.renderContent(content.content, index, level + 1, renderedTags);
                }
            }

            const noTextComponentTags = ['ol', 'ul', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'quote'];
            if (level === 0 && !renderedTags[index].filter(tag => noTextComponentTags.indexOf(tag) !== -1).length) {
                const TextComponent = this.props.textComponent;
                // This adds a linebreak if it gets an empty string.
                if (newContent === '') {
                    return <NewLine/>;
                }
                return <TextComponent>{newContent}</TextComponent>;
            }

            return newContent;
        }
    };

    render() {
        let { data } = this.props;

        if (!data) {
            return null;
        }

        //This is only to handle legacy strings.
        if (typeof data === 'string') {
            data = data.split('\n');
        }

        //This is what actually comes from the cms (an array).
        return data.map((row, index) => {
            return <Fragment key={index}>{this.renderContent(row, index)}</Fragment>;
        });
    }
}

