import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'src/icons/Spinner';
import styled from '@emotion/styled';

const Wrapper = styled('div')`
    animation: spin 1s linear infinite;
    width: 20px;
    height: 20px;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const LoadingIndicator = ({ wrapperStyle, spinnerStyle }) => (
    <Wrapper {...wrapperStyle}>
        <Spinner {...spinnerStyle} />
    </Wrapper>
);

LoadingIndicator.propTypes = {
    spinnerStyle: PropTypes.object,
    wrapperStyle: PropTypes.object,
};

LoadingIndicator.defaultProps = {
    spinnerStyle: {},
    wrapperStyle: {
        color: 'fff',
        height: '20px',
        width: '20px',
    },
};

export default LoadingIndicator;
