import { get } from 'src/libs/BergCommerceAPI/util';
import { getBasketId } from './Cookies';

export default async () => {

    const basketId = await getBasketId();

    const data = {
        'thumb': true
    };
    return await get(`/get-cart?cart_key=${basketId}&thumb=true`, data);
};
