import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';

const Wrapper = styled('div')`
    width: 24px;
    height: 16px;
    position: relative;
`;

const LineWrapper = styled('div')`
    height: 100%;

    div {
        background-color: ${theme.color.black};
        position: absolute;
        top: 50%;
        left: 0%;
        width: 20px;
        height: 1px;
        transition: width 0.2s;

        &::before,
        &::after {
            content: '';
            background-color: ${theme.color.black};
            height: 1px;
            position: absolute;
            opacity: 1;
            transition: top 0.2s, opacity 0.1s;
        }

        &::before {
            top: -6px;
            width: 22px;
        }

        &::after {
            top: 6px;
            width: 18px;

        }

        &.active {
            width: 29px;

            &::before,
            &::after {
                top: 50%;
                opacity: 0;
            }
        }
    }
`;

const BurgerButton = ({ active, onClick, ...rest}) => {
    return (
        <Wrapper onClick={onClick} {...rest} >
            <LineWrapper >
                <div className={active ? 'active' : null}/>
            </LineWrapper>
        </Wrapper>
    );
};

export default BurgerButton;

BurgerButton.propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func,
};

BurgerButton.defaultProps = {
    active: false,
    onClick: null,
};
