import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';

const excludeProps = ['size', 'textMargin'];

const Label = styled('label')`
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: fit-content;
    user-select: none;

    input:checked ~ .marker {
        &::before,&::after {
            content: '';
            height: 1px;
            width: 100%;
            transform-origin: center center;
            top: 50%;
            left: 50%;
            position: absolute;
            background-color: ${theme.color.black};
            transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after {
            transform: translate(-50%, -50%) rotate(-45deg) ;
        }
    }
`;

const Text = styled('span', { shouldForwardProp: prop => excludeProps.indexOf(prop) === -1 })`
    user-select: 'none';
    ${({fontSizes}) =>
        fontSizes.map(fontSize => `${fontSize};`)
    };
    margin: ${({ textMargin }) => (textMargin ? textMargin : '0 0 0 8px')};
    font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : '400')};
    line-height: ${({lineHeight}) => (lineHeight ? lineHeight : '1')};
    color: ${({ color }) => color};
    padding-top: 1px;
`;

const Button = styled('span', { shouldForwardProp: prop => excludeProps.indexOf(prop) === -1 })`
    display: block;
    border: 1px solid ${({ color }) => color};
    height: ${({ size }) => size};
    width: ${({ size }) => size};
    min-width: ${({ size }) => size};
    background: transparent;
    transition: background 0.2s linear;
    cursor: pointer;
    margin: 0;
    position: relative;
`;

const InvisibleInput = styled('input')`
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`;

const CheckboxButton = ({
    checked,
    children,
    color,
    fontSizes,
    fontWeight,
    id,
    letterSpacing,
    lineHeight,
    margin,
    name,
    onClick,
    required,
    size,
    textMargin,
    textTransform,
    value,
    ...rest
}) => (
    <Label className="inputwrapper" htmlFor={id || name} color={color} margin={margin} onClick={onClick} {...rest}>
        <InvisibleInput name={name} type="checkbox" value={value} id={id || name} defaultChecked={checked} required={required} />
        <Button className="marker" color={color} size={size} />
        {children &&
            <Text lineHeight={lineHeight} fontWeight={fontWeight} fontSizes={fontSizes} textMargin={textMargin} color={color} textTransform={textTransform} letterSpacing={letterSpacing}>
                {children}
            </Text>
        }
    </Label>
);

CheckboxButton.propTypes = {
    checked: PropTypes.bool,
    children: PropTypes.node,
    color: PropTypes.string,
    fontSizes: PropTypes.arrayOf(PropTypes.string),
    fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
    letterSpacing: PropTypes.string,
    lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    margin: PropTypes.string,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    required: PropTypes.bool,
    size: PropTypes.string,
    textMargin: PropTypes.string,
    textTransform: PropTypes.string,
    value: PropTypes.string,
};

CheckboxButton.defaultProps = {
    checked: false,
    children: null,
    color: theme.color.black,
    fontSizes: ['font-size: 14px'],
    fontWeight: '400',
    id: null,
    letterSpacing: '',
    lineHeight: '1',
    margin: '',
    onClick: null,
    required: false,
    size: '20px',
    textMargin: '',
    textTransform: '',
    value: ''
};

export default CheckboxButton;
