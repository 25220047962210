import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';

const Wrapper = styled('div')`
    width: 30px;
    height: 30px;
    position: relative;
`;

const LineWrapper = styled('div')`
    position: relative;
    width: 30px;
    height: 30px;
    ${({ rotated }) => rotated && 'transform: rotate(45deg)'};

    &:hover {
        div {
            &::after,
            &::before {
                width: 50%;
            }
        }
    }

    div {
        height: 1px;
        width: 21px;
        position: absolute;
        top: 15px;
        left: 4px;
        transform-origin: top left;

        .activeCrossButton & {
            &::before,
            &::after {
                width: 50%;
            }
        }
        & + div {
            transform: translate(11px, -10px) rotate(90deg);
            transition: transform 0.2s, width 0.2s;
            .activeCrossButton & {
                width: 0px;
                transform: translate(11px, 0px) rotate(90deg);
            }
        }

        &::before,
        &::after {
            content: '';
            background-color: ${theme.color.black};
            position: absolute;
            top: 0;
            left: 0;
            width: 38%;
            height: 100%;
            display: block;
            transition: width 0.2s;
        }

        &::after {
            left: auto;
            right: 0;
        }
    }
`;

const CrossButton = ({ active, rotated, onClick, ...rest}) => {
    return (
        <Wrapper onClick={onClick} {...rest} >
            <LineWrapper className={active ?? 'activeCrossButton'} active={active} rotated={rotated} >
                <div />
                <div />
            </LineWrapper>
        </Wrapper>
    );
};

export default CrossButton;

CrossButton.propTypes = {
    active: PropTypes.bool,
    onClick: PropTypes.func,
    rotated: PropTypes.bool,
};

CrossButton.defaultProps = {
    active: false,
    onClick: null,
    rotated: false,
};
