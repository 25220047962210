import { above, below } from 'src/components/global/mediaqueries';
import dynamicScaling from 'src/utils/dynamicScaling';

const theme = {
    dynamicScaling: (minValue, maxValue, minWidth, maxWidth) =>
        `calc(${minValue}px + (${maxValue} - ${minValue}) * ((100vw - ${minWidth}px) / (${maxWidth} - ${minWidth})))`,
    // FONT SIZE
    //
    fontSize: {
        sans: {
            desktop: {
                _80: `
                    ${above.desktop_s} {
                        font-size: ${dynamicScaling(64, 80, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        font-size: ${dynamicScaling(80, 142, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        font-size: 142px;
                    }
                `,
                _64: `
                    ${above.desktop_s} {
                        font-size: ${dynamicScaling(51, 64, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        font-size: ${dynamicScaling(64, 114, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        font-size: 114px;
                    }
                `,
                _56: `
                    ${above.desktop_s} {
                        font-size: ${dynamicScaling(45, 56, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        font-size: ${dynamicScaling(56, 99, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        font-size: 99px;
                    }
                `,
                _40: `
                    ${above.desktop_s} {
                        font-size: ${dynamicScaling(32, 40, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        font-size: ${dynamicScaling(40, 71, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        font-size: 71px;
                    }
                `,
                _32: `
                    ${above.desktop_s} {
                        font-size: ${dynamicScaling(26, 32, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        font-size: ${dynamicScaling(32, 57, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        font-size: 57px;
                    }
                `,
                _24: `
                    ${above.desktop_s} {
                        font-size: ${dynamicScaling(19, 24, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        font-size: ${dynamicScaling(24, 43, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        font-size: 43px;
                    }
                `,
                _20: `
                    ${above.desktop_s} {
                        font-size: ${dynamicScaling(16, 20, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        font-size: ${dynamicScaling(20, 36, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        font-size: 36px;
                    }
                `,
            },
            tablet: {
                _40: `
                    ${above.tablet_s} {
                        font-size: ${dynamicScaling(34.53, 40, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        font-size: ${dynamicScaling(40, 55.2, 'tablet_l', 'tablet_l')};
                    }
                `,
                _32: `
                    ${above.tablet_s} {
                        font-size: ${dynamicScaling(27.63, 32, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        font-size: ${dynamicScaling(32, 44.16, 'tablet_l', 'tablet_l')};
                    }
                `,
                _24: `
                    ${above.tablet_s} {
                        font-size: ${dynamicScaling(20.72, 24, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        font-size: ${dynamicScaling(24, 33.12, 'tablet_l', 'tablet_l')};
                    }
                `,
                _20: `
                    ${above.tablet_s} {
                        font-size: ${dynamicScaling(20, 20, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        font-size: ${dynamicScaling(20, 27.6, 'tablet_l', 'tablet_l')};
                    }
                `,
            },
            mobile: {
                _40: `
                    font-size: 34.53px;
                    ${above.mobile_s} {
                        font-size: ${dynamicScaling(34.13, 40, 'mobile_s', 'mobile_s')};
                    }
                    ${above.mobile_l} {
                        font-size: ${dynamicScaling(40, 76.69, 'mobile_l', 'mobile_l')};
                    }
                `,
                _32: `
                    font-size: 27.31px;
                    ${above.mobile_s} {
                        font-size: ${dynamicScaling(27.31, 32, 'mobile_s', 'mobile_s')};
                    }
                    ${above.mobile_l} {
                        font-size: ${dynamicScaling(32, 61.35, 'mobile_l', 'mobile_l')};
                    }
                `,
                _24: `
                    font-size: 20.48;
                    ${above.mobile_s} {
                        font-size: ${dynamicScaling(20.48, 24, 'mobile_s', 'mobile_s')};
                    }
                    ${above.mobile_l} {
                        font-size: ${dynamicScaling(24, 46.02, 'mobile_l', 'mobile_l')};
                    }
                `,
                _20: `
                    font-size: 17.07px;
                    ${above.mobile_s} {
                        font-size: ${dynamicScaling(17.07, 20, 'mobile_s', 'mobile_s')};
                    }
                    ${above.mobile_l} {
                        font-size: ${dynamicScaling(20, 38.35, 'mobile_l', 'mobile_l')};
                    }
                `,
            },
        },
        serif: {
            desktop: {
                _48: `
                    ${above.desktop_s} {
                        font-size: ${dynamicScaling(38, 48, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        font-size: ${dynamicScaling(48, 85, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        font-size: 85px;
                    }
                `,
                _40: `
                    ${above.desktop_s} {
                        font-size: ${dynamicScaling(32, 40, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        font-size: ${dynamicScaling(40, 71, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        font-size: 71px;
                    }
                `,
                _32: `
                    ${above.desktop_s} {
                        font-size: ${dynamicScaling(26, 32, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        font-size: ${dynamicScaling(32, 57, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        font-size: 57px;
                    }
                `,
                _24: `
                    ${above.desktop_s} {
                        font-size: ${dynamicScaling(19, 24, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        font-size: ${dynamicScaling(24, 43, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        font-size: 43px;
                    }
                `,
                _20: `
                    ${above.desktop_s} {
                        font-size: ${dynamicScaling(16, 20, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        font-size: ${dynamicScaling(20, 36, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        font-size: 36px;
                    }
                `,
            },
            tablet: {
                _24: `
                    ${above.tablet_s} {
                        font-size: ${dynamicScaling(20.75, 24, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        font-size: ${dynamicScaling(24, 33.12, 'tablet_l', 'tablet_l')};
                    }
                `,
                _20: `
                    ${above.tablet_s} {
                        font-size: ${dynamicScaling(17.29, 20, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        font-size: ${dynamicScaling(20, 26.7, 'tablet_l', 'tablet_l')};
                    }
                `,
            },
            mobile: {
                _24: `
                    font-size: 20.48;
                    ${above.mobile_s} {
                        font-size: ${dynamicScaling(20.48, 24, 'mobile_s', 'mobile_s')};
                    }
                    ${above.mobile_l} {
                        font-size: ${dynamicScaling(24, 46.02, 'mobile_l', 'mobile_l')};
                    }
                `,
                _20: `
                    font-size: 17.07px;
                    ${above.mobile_s} {
                        font-size: ${dynamicScaling(17.07, 20, 'mobile_s', 'mobile_s')};
                    }
                    ${above.mobile_l} {
                        font-size: ${dynamicScaling(20, 38.35, 'mobile_l', 'mobile_l')};
                    }
                `,
                _16: `
                    font-size: 16px;
                    ${above.mobile_s} {
                        font-size: ${dynamicScaling(16, 16, 'mobile_s', 'mobile_s')};
                    }
                    ${above.mobile_l} {
                        font-size: ${dynamicScaling(16, 30.68, 'mobile_l', 'mobile_l')};
                    }
                `,
            },
        },
    },

    // Padding
    //
    spacing: {
        desktop: {
            _120: attribute => {
                return `
                    ${above.desktop_s} {
                        ${attribute}: ${dynamicScaling(96, 120, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        ${attribute}: ${dynamicScaling(120, 213.33, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        ${attribute}: 213.33px;
                    }
                `;
            },
            _88: attribute => {
                return `
                    ${above.desktop_s} {
                        ${attribute}: ${dynamicScaling(70, 88, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        ${attribute}: ${dynamicScaling(88, 156, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        ${attribute}: 156px;
                    }
                `;
            },
            _64: attribute => {
                return `
                    ${above.desktop_s} {
                        ${attribute}: ${dynamicScaling(51, 64, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        ${attribute}: ${dynamicScaling(64, 114, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        ${attribute}: 114px;
                    }
                `;
            },
            _48: attribute => {
                return `
                    ${above.desktop_s} {
                        ${attribute}: ${dynamicScaling(38.4, 48, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        ${attribute}: ${dynamicScaling(48, 85.33, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        ${attribute}: 85.33px;
                    }
                `;
            },
            _40: attribute => {
                return `
                    ${above.desktop_s} {
                        ${attribute}: ${dynamicScaling(32, 40, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        ${attribute}: ${dynamicScaling(40, 71, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        ${attribute}: 71px;
                    }
                `;
            },
            _32: attribute => {
                return `
                    ${above.desktop_s} {
                        ${attribute}: ${dynamicScaling(26, 32, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        ${attribute}: ${dynamicScaling(32, 57, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        ${attribute}: 57px;
                    }
                `;
            },
            _24: attribute => {
                return `
                    ${above.desktop_s} {
                        ${attribute}: ${dynamicScaling(19, 24, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        ${attribute}: ${dynamicScaling(24, 43, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        ${attribute}: 43px;
                    }
                `;
            },
            _20: attribute => {
                return `
                    ${above.desktop_s} {
                        ${attribute}: ${dynamicScaling(16, 20, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        ${attribute}: ${dynamicScaling(20, 35.56, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        ${attribute}: 28px;
                    }
                `;
            },
            _16: attribute => {
                return `
                    ${above.desktop_s} {
                        ${attribute}: ${dynamicScaling(13, 16, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        ${attribute}: ${dynamicScaling(16, 28, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        ${attribute}: 28px;
                    }
                `;
            },
            _12: attribute => {
                return `
                    ${above.desktop_s} {
                        ${attribute}: ${dynamicScaling(9, 12, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        ${attribute}: ${dynamicScaling(12, 21, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        ${attribute}: 21px;
                    }
                `;
            },
            _10: attribute => {
                return `
                    ${above.desktop_s} {
                        ${attribute}: ${dynamicScaling(8, 10, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        ${attribute}: ${dynamicScaling(10, 17.78, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        ${attribute}: 17.78px;
                    }
                `;
            },
            _8: attribute => {
                return `
                    ${above.desktop_s} {
                        ${attribute}: ${dynamicScaling(6, 8, 'desktop_s', 'desktop_s')};
                    }
                    ${above.desktop_l} {
                        ${attribute}: ${dynamicScaling(8, 14.22, 'desktop_l', 'desktop_l')};
                    }
                    ${above.desktop_xl} {
                        ${attribute}: 14.22px;
                    }
                `;
            },
            _0: attribute => {
                return `
                    ${above.desktop_s} {
                        ${attribute}: 0;
                    }
                    ${above.desktop_l} {
                        ${attribute}: 0;
                    }
                    ${above.desktop_xl} {
                        ${attribute}: 0;
                    }
                `;
            },
        },
        tablet: {
            _120: attribute => {
                return `
                    ${above.tablet_s} {
                        ${attribute}: ${dynamicScaling(103.74, 120, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        ${attribute}: ${dynamicScaling(120, 165.61, 'tablet_l', 'tablet_l')};
                    }
                `;
            },
            _88: attribute => {
                return `
                    ${above.tablet_s} {
                        ${attribute}: ${dynamicScaling(76.08, 88, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        ${attribute}: ${dynamicScaling(88, 121.45, 'tablet_l', 'tablet_l')};
                    }
                `;
            },
            _64: attribute => {
                return `
                    ${above.tablet_s} {
                        ${attribute}: ${dynamicScaling(55.33, 64, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        ${attribute}: ${dynamicScaling(64, 88.3, 'tablet_l', 'tablet_l')};
                    }
                `;
            },
            _56: attribute => {
                return `
                    ${above.tablet_s} {
                        ${attribute}: ${dynamicScaling(48.41, 56, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        ${attribute}: ${dynamicScaling(56, 77.29, 'tablet_l', 'tablet_l')};
                    }
                `;
            },
            _40: attribute => {
                return `
                    ${above.tablet_s} {
                        ${attribute}: ${dynamicScaling(34.58, 40, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        ${attribute}: ${dynamicScaling(40, 55.2, 'tablet_l', 'tablet_l')};
                    }
                `;
            },
            _32: attribute => {
                return `
                    ${above.tablet_s} {
                        ${attribute}: ${dynamicScaling(27.66, 32, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        ${attribute}: ${dynamicScaling(32, 44.16, 'tablet_l', 'tablet_l')};
                    }
                `;
            },
            _24: attribute => {
                return `
                    ${above.tablet_s} {
                        ${attribute}: ${dynamicScaling(20.75, 24, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        ${attribute}: ${dynamicScaling(24, 27.6, 'tablet_l', 'tablet_l')};
                    }
                `;
            },
            _20: attribute => {
                return `
                    ${above.tablet_s} {
                        ${attribute}: ${dynamicScaling(17.29, 20, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        ${attribute}: ${dynamicScaling(20, 27.6, 'tablet_l', 'tablet_l')};
                    }
                `;
            },
            _16: attribute => {
                return `
                    ${above.tablet_s} {
                        ${attribute}: ${dynamicScaling(13.83, 16, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        ${attribute}: ${dynamicScaling(16, 22.08, 'tablet_l', 'tablet_l')};
                    }
                `;
            },
            _12: attribute => {
                return `
                    ${above.tablet_s} {
                        ${attribute}: ${dynamicScaling(10.37, 12, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        ${attribute}: ${dynamicScaling(12, 16.56, 'tablet_l', 'tablet_l')};
                    }
                `;
            },
            _10: attribute => {
                return `
                    ${above.tablet_s} {
                        ${attribute}: ${dynamicScaling(8.65, 10, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        ${attribute}: ${dynamicScaling(10, 13.8, 'tablet_l', 'tablet_l')};
                    }
                `;
            },
            _8: attribute => {
                return `
                    ${above.tablet_s} {
                        ${attribute}: ${dynamicScaling(6.92, 8, 'tablet_s', 'tablet_s')};
                    }
                    ${above.tablet_l} {
                        ${attribute}: ${dynamicScaling(8, 11.04, 'tablet_l', 'tablet_l')};
                    }
                `;
            },
            _0: attribute => {
                return `
                    ${above.tablet_s} {
                        ${attribute}: 0;
                    }
                    ${above.tablet_l} {
                        ${attribute}: 0;
                    }
                `;
            },
        },
        mobile: {
            _64: attribute => {
                return `
                    ${below.mobile_s} {
                        ${attribute}: 54px;
                    }
                    ${above.mobile_s} {
                        ${attribute}: ${dynamicScaling(54, 64, 'mobile_s', 'mobile_s')};
                    }
                    ${above.mobile_l} {
                        ${attribute}: ${dynamicScaling(64, 123, 'mobile_l', 'mobile_l')};
                    }
                `;
            },
            _40: attribute => {
                return `
                    ${below.mobile_s} {
                        ${attribute}: 34px;
                    }
                    ${above.mobile_s} {
                        ${attribute}: ${dynamicScaling(34, 40, 'mobile_s', 'mobile_s')};
                    }
                    ${above.mobile_l} {
                        ${attribute}: ${dynamicScaling(40, 77, 'mobile_l', 'mobile_l')};
                    }
                `;
            },
            _32: attribute => {
                return `
                    ${below.mobile_s} {
                        ${attribute}: 27px;
                    }
                    ${above.mobile_s} {
                        ${attribute}: ${dynamicScaling(27, 32, 'mobile_s', 'mobile_s')};
                    }
                    ${above.mobile_l} {
                        ${attribute}: ${dynamicScaling(32, 61, 'mobile_l', 'mobile_l')};
                    }
                `;
            },
            _24: attribute => {
                return `
                    ${below.mobile_s} {
                        ${attribute}: 21px;
                    }
                    ${above.mobile_s} {
                        ${attribute}: ${dynamicScaling(21, 24, 'mobile_s', 'mobile_s')};
                    }
                    ${above.mobile_l} {
                        ${attribute}: ${dynamicScaling(24, 46, 'mobile_l', 'mobile_l')};
                    }
                `;
            },
            _20: attribute => {
                return `
                    ${below.mobile_s} {
                        ${attribute}: 17.07px;
                    }
                    ${above.mobile_s} {
                        ${attribute}: ${dynamicScaling(17.07, 20, 'mobile_s', 'mobile_s')};
                    }
                    ${above.mobile_l} {
                        ${attribute}: ${dynamicScaling(20, 38.35, 'mobile_l', 'mobile_l')};
                    }
                `;
            },
            _16: attribute => {
                return `
                    ${below.mobile_s} {
                        ${attribute}: 14px;
                    }
                    ${above.mobile_s} {
                        ${attribute}: ${dynamicScaling(14, 16, 'mobile_s', 'mobile_s')};
                    }
                    ${above.mobile_l} {
                        ${attribute}: ${dynamicScaling(16, 31, 'mobile_l', 'mobile_l')};
                    }
                `;
            },
            _12: attribute => {
                return `
                    ${below.mobile_s} {
                        ${attribute}: 10px;
                    }
                    ${above.mobile_s} {
                        ${attribute}: ${dynamicScaling(10, 12, 'mobile_s', 'mobile_s')};
                    }
                    ${above.mobile_l} {
                        ${attribute}: ${dynamicScaling(12, 23, 'mobile_l', 'mobile_l')};
                    }
                `;
            },
            _10: attribute => {
                return `
                    ${below.mobile_s} {
                        ${attribute}: 8.53px;
                    }
                    ${above.mobile_s} {
                        ${attribute}: ${dynamicScaling(8.53, 10, 'mobile_s', 'mobile_s')};
                    }
                    ${above.mobile_l} {
                        ${attribute}: ${dynamicScaling(10, 19.17, 'mobile_l', 'mobile_l')};
                    }
                `;
            },
            _8: attribute => {
                return `
                    ${below.mobile_s} {
                        ${attribute}: 8.53px;
                    }
                    ${above.mobile_s} {
                        ${attribute}: ${dynamicScaling(6.83, 8, 'mobile_s', 'mobile_s')};
                    }
                    ${above.mobile_l} {
                        ${attribute}: ${dynamicScaling(8, 15.32, 'mobile_l', 'mobile_l')};
                    }
                `;
            },
            _0: attribute => {
                return `
                    ${below.mobile_s} {
                        ${attribute}: 0;
                    }
                    ${above.mobile_s} {
                        ${attribute}: 0;
                    }
                    ${above.mobile_l} {
                        ${attribute}: 0;
                    }
                `;
            },
        },
    },

    color: {
        white: '#fff',
        light_grey: '#fafafa',
        grey: '#e4e4e4',
        dark_grey: '#9a9a9a',
        black: '#000',
        dusty_green: '#7d8e86',
        forest_green: '#384b45',
        sky_blue: '#b6ccda',
        dark_blue: '#202c46',
        warm: '#d7cfc6',
        mustard: '#b58d55',
        berries: '#a8514e',
        brown: '#54493c',
        productcard: '#ebe7e2',
        footer: '#e5dfd8',
    },

    backgroundTextColor: {
        white: '#000',
        light_grey: '#fff',
        grey: '#fff',
        dark_grey: '#fff',
        black: '#fff',
        dusty_green: '#fff',
        forest_green: '#fff',
        sky_blue: '#fff',
        dark_blue: '#fff',
        warm: '#000',
        mustard: '#fff',
        berries: '#fff',
        brown: '#fff',
    },

    backgroundButtonColor: {
        white: 'black',
        light_grey: 'white',
        grey: 'white',
        dark_grey: 'white',
        black: 'white',
        dusty_green: 'white',
        forest_green: 'white',
        sky_blue: 'white',
        dark_blue: 'white',
        warm: 'black',
        mustard: 'white',
        berries: 'white',
        brown: 'white',
    },

    blur: {
        none: '0',
        standard: {
            desktop: '10px',
            mobile: '10px',
        },
    },
};

export default theme;
