import * as React from 'react';
import styled from '@emotion/styled';
import { labels, breakpoints, breakpointsMax } from './breakpointValues';

const AboveWrapper = styled('div')`
    @media (max-width: ${(props) =>
        breakpointsMax[labels.indexOf(props.breakpoint)]}) {
            display: none;
        }
`;

const BelowWrapper = styled('div')`
    @media (min-width: ${(props) =>
        breakpoints[labels.indexOf(props.breakpoint)]}) {
            display: none;
        }
`;

const AboveBreakpointValue = (breakpoint) => {
    const width = breakpointsMax[labels.indexOf(breakpoint)];
    const widthNumericValue = String(width).replace(/[^0-9.]/g, '');
    return widthNumericValue;
};

const BelowBreakpointValue = (breakpoint) => {
    const width = breakpoints[labels.indexOf(breakpoint)];
    const widthNumericValue = String(width).replace(/[^0-9.]/g, '');
    return widthNumericValue;
};

const Above = ({ breakpoint, children, ...props }) => (
    <AboveWrapper breakpoint={breakpoint} {...props}>
        {children}
    </AboveWrapper>
);
const Below = ({ breakpoint, children, ...props }) => (
    <BelowWrapper breakpoint={breakpoint} {...props}>
        {children}
    </BelowWrapper>
);

export { Above, Below, AboveBreakpointValue, BelowBreakpointValue };
