import { get } from 'src/libs/BergCommerceAPI/util';
import { getBasketId } from './Cookies';

export default async () => {
    const basketId = await getBasketId();

    const data = {
        'cart_key': basketId,
    };
    return await get('/count-items/', data);
};
